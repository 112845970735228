import _Config from './Config'
import _LoginInfo from './LoginInfo'
import _JsCall from '../view/JsCall'
import _SVG from './SVG'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Buffer } from 'buffer'
import { toast } from 'react-toastify'
import io from 'socket.io-client'
import * as CryptoJS from 'crypto-js';
import { EncryptStorage } from 'encrypt-storage'
import notificationSuccess from '../assets/images/tosti-notification.png'
import notificationWarning from '../assets/images/warning-notification.png'
import notificationError from '../assets/images/error-notification.png'
import notificationErrorbg from '../assets/images/error.png'
import bellnotification from '../assets/images/time-tosti.png'
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js'
import * as AWS from 'aws-sdk'
import { store } from '../index'
import { cloneDeep } from 'lodash'
import { jwtDecode } from "jwt-decode"


window.Buffer = Buffer
// mohit
export var Config = new _Config()
export var LoginInfo = new _LoginInfo()
export var JsCall = new _JsCall()
// Nirzar Gabani 💀 ~  2024-10-14 ~ 10:07 AM
export var SVG = new _SVG()

export var socket = io.connect(Config.getSocketserver())

export var url
export var reqHeaders
export var reqBody
export var resHeaders
export var resBody
export var s3sessoin
export var AWSService = AWS
export const pagelimit = [
    {
        "label": 20,
        "value": 20,
    },
    {
        "label": 50,
        "value": 50,
    },
    {
        "label": 100,
        "value": 100,
    },
    {
        "label": 500,
        "value": 500,
    },
    {
        "label": 1000,
        "value": 1000,
    }
]

// date formate
export const dateformate = [
    {
        "label": '09/01/2022 (DD/MM/YYYY)',
        "value": 7,
    },
    {
        "label": '2022-09-01 (YYYY-MM-DD)',
        "value": 1,
    },
    {
        "label": '9/1/2022 (MM/DD/YYYY)',
        "value": 2,
    },
    {
        "label": '9/1/22 (MM/DD/YY)',
        "value": 3,
    },
    {
        "label": '01-sep-22',
        "value": 4,
    },
    {
        "label": '22/09/01 (YY/MM/DD)',
        "value": 5,
    },
    {
        "label": '09/01/22 (MM/DD/YY)',
        "value": 6,
    },
]

// time formate
export const timeformat = [
    {
        "label": '1:01 AM - 11:59 PM',
        "value": 1,
    },
    {
        "label": '1:01-23:59',
        "value": 2,
    }
]

export const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const allowdedFiles = [".doc", ".docx", ".pdf", ".xlsx", ".pptx", ".png", ".jpg"]

// 9/12/2022 hemang
export const allowdedImage = {
    'png': '.png',
    'jpg': '.jpg',
    'jpeg': '.jpeg',
    'gif': '.gif',
    'svg': '.svg',
}

export const wordExtensions = [".doc", ".docx"]

export const pptExtensions = [".ppt", ".pptm", ".pptx"]

export const exexlExtensions = [".xlsx", ".xls"]

export const allowdedVideoFiles = [".mov", ".MOV", ".wmv", ".avi", ".mp4", ".avchd", ".flv", ".f4v", ".swf", ".mkv", ".webm"]

export const allowdedDocuments = {
    'pdf': '.pdf',
    'word': wordExtensions,
    'exel': exexlExtensions,
    'ppt': pptExtensions,
    'video': allowdedVideoFiles
}
// 9/12/2022 hemang

export const allowdedCodeFiles = {
    'php': '.php',
}

// [".png" , ".jpg", ".jpeg", ".gif" , ".svg" ]

export async function setS3Session(s3_Sessoin) {
    s3sessoin = s3_Sessoin
}

export async function setReqParams(URL, reqbody) {
    url = URL
    reqBody = reqbody
}


// java script class
class _IISMethods {

    constructor() {
    }

    getCurrentState() {
        return store.getState()
    }

    /******************************************************************  Hard Refresh Start      *******************************************************************/
    HardRefresh() {

        // cache busting: Reliable but modifies URL
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set(`${this.generateRandomIv(2)}`, new Date().getTime());
        var query = queryParams.toString();
        // query logic not working every time
        // window.location.search = query;  // navigates
        if (IISMethods.getpagename() == 'home') {
            window.location.href = '/masters'
        }
        else {
            window.location.href = '/home'
        }
        // window.
    }

    /******************************************************************  Hard Refresh End      *******************************************************************/

    // get OS Details
    // to get user device os
    getOSDetail() {
        let userAgent = window.navigator.userAgent
        let macosPlatforms = /(Macintosh|MacIntel|MacPPC|Mac68K)/i
        let windowsPlatforms = /(Win32|Win64|Windows|WinCE)/i
        let iosPlatforms = /(iPhone|iPad|iPod)/i
        let os = null

        if (macosPlatforms.test(userAgent)) {
            os = 'Mac OS'
        }
        else if (iosPlatforms.test(userAgent)) {
            os = 'iOS'
        }
        else if (windowsPlatforms.test(userAgent)) {
            os = 'Windows'
        }
        else if (/Android/i.test(userAgent)) {
            os = 'Android'
        }
        else if (!os && /Linux/i.test(userAgent)) {
            os = 'Linux'
        }

        return os
    }

    /********************************************************************** Cookie & Local Storage Methods Start***********************************************************************/

    // ********************************************************************** COOCKIE METHODS START **********************************************************************

    /**
     * set Cookie data 
     * @param {String} key 
     * @param {Any} value 
     * @param {String} path do not change
     */
    //  setCookiesData(key, value, path = '/') {
    //     try {
    //         const cookies = new Cookies()
    //         // cookie max age 24 hr
    //         // , maxAge: 12 * 60 * 60
    //         // remove max age for log in all time
    //         cookies.set(key, value, { path: path , maxAge: 12 * 60 * 60})
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    setCookiesData(key, value, path = '/') {
        try {
            const cookies = new Cookies()
            const loginData = this.getLocalData("loginCredentials")
            // console.log(loginData,'loginData0')
            // cookie max age 24 hr
            // , maxAge: 12 * 60 * 60
            // remove max age for log in all time

            if (loginData?.rememberme) {
                //731 days cookie expire time
                cookies.set(key, value, { path: path, maxAge: 1735707600 })
            } else {
                cookies.set(key, value, { path: path, maxAge: 12 * 60 * 60 })
            }
        } catch (err) {
            console.error(err)
        }
    }

    /**
     * get Cookie data
     * @param {String} key 
     * @returns 
     */
    getCookiesData(key) {
        const cookies = new Cookies()
        return cookies.get(key)
    }

    /**
     * clear cookie used in logout method
     * @param {String} key 
     * @param {String} path do not change
     */
    clearCookiesData(key, path = '/') {
        const cookies = new Cookies()
        cookies.remove(key, { path: path })
    }

    // ********************************************************************** COOCKIE METHODS END *******************************************************************

    // ********************************************************************** Session Storage METHODS START *******************************************************************
    /**
     * set Session Storage 
     * @param {String} key 
     * @param {Any} value 
     */
    setSessionData(key, value) {
        if (Config.encryptStorage) {
            let encryptStorage1 = new EncryptStorage('secret-key-value', {
                prefix: '@instance1',
                storageType: 'sessionStorage',
            });
            encryptStorage1.setItem(key, JSON.stringify(value))
        }
        else {
            try {
                sessionStorage.setItem(key, JSON.stringify(value))
            } catch (err) {
            }
        }

    }

    /**
     * get Session Storage
     * @param {String} key 
     * @returns 
     */
    getSessionData(key) {
        if (Config.encryptStorage) {
            let encryptStorage1 = new EncryptStorage('secret-key-value', {
                prefix: '@instance1',
                storageType: 'sessionStorage',
            });
            let storage = encryptStorage1.getItem(key)
            return storage ? storage : null
        }
        else {
            return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : {}
        }
    }

    /**
     * clear Session used in logout method
     * @param {String} key 
     */
    clearSessionData(key) {
        sessionStorage.removeItem(key)
    }

    // ********************************************************************** Session Storage METHODS END *******************************************************************

    // ********************************************************************** LOCALSTORAGE METHODS START ***************************************************************

    /**
     * set LocalStorage
     * @param {String} key 
     * @param {Any} value 
     */
    setLocalData(key, value) {
        // if(this.getpagename() == '' || this.getpagename() == 'home' || this.getpagename() == 'login'){
        //     try{
        //         // encrypt local storage
        //         localStorage.setItem(key, JSON.stringify(value))
        //         let encrypteddata = this.encryptData(value)
        //         localStorage.setItem(key,encrypteddata)
        //     }
        //     catch(e){
        //         localStorage.setItem(key, JSON.stringify(value))
        //     }
        // }
        // else{

        if (Config.encryptStorage && key == 'loginCredentials') {
            let encryptStorage1 = new EncryptStorage('secret-key-value', {
                prefix: '@instance1',
            });

            encryptStorage1.setItem(key, JSON.stringify(value))
        }
        else {
            localStorage.setItem(key, JSON.stringify(value))
        }
        // }
    }

    /**
     * get LocalStorage
     * @param {String} key 
     * @returns 
     */
    getLocalData(key) {

        // if(this.getpagename() == '' || this.getpagename() == 'home' || this.getpagename() == 'login' || this.getpagename() == 'recruitmentdashboard'){
        //     try{
        //         // 
        //         let decrypteddata = this.decryptData(localStorage.getItem(key) || '')
        //         return JSON.parse(decrypteddata)
        //     }
        //     catch(e){
        //         return JSON.parse(localStorage.getItem(key))
        //     }
        // }
        // else{
        if (Config.encryptStorage && key == 'loginCredentials') {
            let encryptStorage1 = new EncryptStorage('secret-key-value', {
                prefix: '@instance1',
            });
            let storage = encryptStorage1.getItem(key)
            return storage ? storage : null
        }
        else {
            return JSON.parse(localStorage.getItem(key))
        }
        // }

        // try{
        //     // let decrypteddata = this.decryptDat
        //     // return JSON.parse(JSON.parse(decrypteddata)[key])
        //     return JSON.parse(localStorage.getItem(key))
        // }
        // catch(e){}
        // // return JSON.parse(localStorage.getItem(key))
    }

    /**
     * clear LocalStorage Use in Logout method
     * @param {String} key 
     */
    clearLocalData(key) {
        if (Config.encryptStorage && key == 'loginCredentials') {
            key = '@instance1:' + key
            localStorage.removeItem(key)
        }
        else {
            localStorage.removeItem(key)
        }
    }

    //********************************************************************** LOCALSTORAGE METHODS END **********************************************************************

    /********************************************************************** Cookie & Local Storage Methods End***********************************************************************/


    /**
     * to get alias / pagename from web url
     * @returns last '/' value from url 
     * http://192.168.2.2:3000/userrolemaster returns userrolemaster
     * http://192.168.2.2:3000/tasks/plainview returns plainview
     */
    getpagename() {
        var urlpath = window.location.pathname
        var pagename = urlpath.split("/")
        var pagename = pagename[pagename.length - 1]
        return pagename
    }

    // hemang 18/1/23
    /**
     * to get all pathnames from web url
     * @returns Array of pathname from web url
     * http://192.168.2.2:3000/userrolemaster returns ['userrolemaster']
     * http://192.168.2.2:3000/tasks/plainview returns ['tasks','plainview']
     */
    getPageUrlParams() {
        var urlpath = window.location.pathname
        var pagename = urlpath.split("/")
        return pagename
    }

    /**
     * to get subdomain name form web url
     * @returns subdomain name from web url 
     * in dev mode http://192.168.2.2:3000/login?q=iis returns 'iis'
     * in prod mode https://instanceit.bizcompass.app returns 'instanceit'
     * if no subdomain found then returns undefined
     */
    getSubdomainName() {
        let subdomain
        const urlparams = Config.getServermode() == 'prod' ? window.location : new URLSearchParams(window.location.search)
        console.log('urlparams', urlparams);
        
        if (Config.getServermode() == 'prod') {
            try {
                // indexOf('.bizcompass.app')
                let domainnameindex = urlparams.hostname?.indexOf('.bizcompass.app')
                console.log('domainnameindex', domainnameindex);
                
                if (domainnameindex > 0) {
                    subdomain = urlparams.hostname?.slice(0, domainnameindex)
                }
                else {
                    subdomain = undefined
                }
            }
            catch {
                subdomain = undefined
            }
        }
        else {
            try {
                subdomain = urlparams.get('q')
            }
            catch {
                subdomain = undefined
            }
        }

        return subdomain
    }

    /**
     * get user rights for page
     * @param {String} pagename 
     * @returns Object containing rights or undefined if no rights found
     */
    // getPageRights(pagename = this.getpagename()) {
    //     // temp comment
    //     // this.getCurrentState()["userRights"]
    //     return this.getobjectfromarray( this.getLocalData("userRights"), 'alias', pagename) || {}
    // }

    getPageRights(pagename = this.getpagename()) {
        return this.getobjectfromarray(this.getCurrentState()["userRights"], 'alias', pagename) || {}
    }


    /**
     * used for making all axios requests
     * @param {String} method 
     * @param {String} url 
     * @param {JSON Object} reqBody 
     * @param {JSON Object} reqHeaders 
     */
    async axiosrequest(method, url, reqBody, reqHeaders, successCallback, errorCallback, cancelToken) {


        await axios({
            method: method,
            url: url,
            data: reqBody,
            headers: reqHeaders,
            // cancelToken: cancelToken?.token || ''
        })
            .then(response => successCallback(response))
            .catch(error => {
                let reqwithStatusCode
                if (error.response) {
                    // The request was made and the server responded with a status code
                    console.group(
                        `%c🛑🚫❌ Response error:\n %cRequest URL: ${url}\n %cTime: ${this.getTimeFormat(new Date().toISOString(), 1)}\n %cStatus Code: ${error.response.status}\n %cMessage: ${error.response.data.message}`,
                        'color: red; font-weight: bold;', // Style for "Response error:"
                        'color: blue; font-weight: bold;', // Style for Request URL:"
                        'color: purple; font-weight: bold;', // Style for Time:"
                        'color: orange; font-weight: bold', // Style for "Status Code:"
                        'color: green;font-weight: bold' // Style for "Message:"
                    )
                    reqwithStatusCode = true
                } else if (error.code === 'ECONNABORTED') {
                    // Connection timeout error
                    console.group(
                        `%c🛑🚫❌ Connection timeout error:\n %cRequest URL: ${url}\n %cTime: ${this.getTimeFormat(new Date().toISOString(), 1)}\n %cMessage: ${error.message}`,
                        'color: red; font-weight: bold;',
                        'color: blue; font-weight: bold;',
                        'color: purple;font-weight: bold',
                        'color: green;font-weight: bold'
                    )
                    reqwithStatusCode = false
                    IISMethods.notify(Config.errmsg.reqtimeout,2)
                } else if (error.message === 'Network Error') {
                    // CORS error or Network error
                    console.group(
                        `%c🛑🚫❌ CORS error or Network error\n %cRequest URL: ${url}\n %cTime: ${this.getTimeFormat(new Date().toISOString(), 1)}`,
                        'color: red; font-weight: bold;',
                        'color: blue; font-weight: bold;',
                        'color: purple;font-weight: bold' ,
                    )
                    reqwithStatusCode = false
                    IISMethods.notify(Config.errmsg.networkerror,2)
                }
                else if (error.request) {
                    // The request was made but no response was received
                    console.group(
                        `%c🛑🚫❌ Request error:\n %cRequest URL: ${url}\n %cTime: ${this.getTimeFormat(new Date().toISOString(), 1)}\n %cMessage: ${error.request}`,
                        'color: red; font-weight: bold;',
                        'color: blue; font-weight: bold;',
                        'color: purple;font-weight: bold',
                        'color: green;font-weight: bold'
                    )
                    reqwithStatusCode = false
                    IISMethods.notify(Config.errmsg.noresreceived,2)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.group(
                        `%c🛑🚫❌ Request error:\n %cRequest URL: ${url}\n %cTime: ${this.getTimeFormat(new Date().toISOString(), 1)}\n %cMessage: ${error.message}`,
                        'color: red; font-weight: bold;',
                        'color: blue; font-weight: bold;',
                        'color: purple;font-weight: bold',
                        'color: green;font-weight: bold'
                    )
                    reqwithStatusCode = false
                    IISMethods.notify(Config.errmsg.reqsetiperror,2)
                }
                errorCallback(error)
            })
    }

    // /**
    //  * to call multiple requests at once 
    //  * @param {String} method 
    //  * @param {String} url 
    //  * @param {JSON Object} reqBody 
    //  * @param {JSON Object} reqHeaders 
    //  */
    // // call multiple req at once
    // async AllAxiosRequest(method, url, reqBody, reqHeaders, successCallback, errorCallback){
    //     await axios.all({
    //         method: method,
    //         url: url,
    //         data: reqBody,
    //         headers: reqHeaders
    //     })
    //     .then(axios.spread((response)=>{
    //         successCallback(response)
    //     }))
    //     .catch(error => errorCallback(error))
    // }
    // // call multiple req at once

    /**
     * to get random number
     * @param {Number} number 
     * @returns random number 
     */
    getRandom(number) {
        return Math.floor((Math.random() * number) + 1)
    }

    /**
     * to get random color from config color array
     * @returns randomly generated color code in hex format
     */
    getRandomColor() {
        return Config.bgColorsArray[Math.floor(Math.random() * 100)]
    }

    /**
     * to get name initials of person name
     * @param {String} name 
     * @returns initial letter of name
     */
    // harsh 20-1-2023
    getDefaulImageIconForName(name) {
        return name?.split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').slice(0, 2)
    }


    /************************************************************* DATE AND TIME METHODS START ************************************************************************************ */

    // convert milisecond to time
    /**
     * convert mili sec to diff time strinfg formate
     * @param {Number} time milliseconds only
     * @param {String} format 
     * @returns returns mili sec to time formates for ex:- 1 day(s) 2hr 3min or +10:05:00
     */
    ConvertTime(time, format = '') {
        var s = Math.floor((time / 1000) % 60)
        var m = Math.floor((time / (1000 * 60)) % 60)
        var h = Math.floor((time / (1000 * 60 * 60)) % 24)
        // var h = (time / (1000 * 60 * 60)) < 24 ? Math.floor((time / (1000 * 60 * 60)) % 24) : Math.floor((time / (1000 * 60 * 60)))
        // var h = Math.floor((time / (1000 * 60 * 60)))
        // var d = Math.floor((time / (1000 * 60 * 60)) / 24)
        var flag = 0
        if (s < 0) {
            flag = 1
            s = -s
        }
        if (m < 0) {
            flag = 1
            m = -m
        }
        if (h < 0) {
            flag = 1
            h = -h
        }

        if (s <= 9)
            s = '0' + s
        if (m <= 9)
            m = '0' + m
        if (h <= 9)
            h = '0' + h

        if (time == 86400000) {
            h = 24
        }

        const ConvertedTime = (format == 'hh-mm-ss' ? `${parseInt((time / (1000 * 60 * 60)) / 24) > 0 ? `${parseInt((time / (1000 * 60 * 60)) / 24)}day(s)` : ''} ${h % 24}hr ${m}min ` : flag ? `-${h}:${m}:${s}` : `+${h}:${m}:${s}`)
        return ConvertedTime
    }

    /**
     * get date in dd/mm/yy or dd Jan , yyyy formate
     * @param {Date Object or String} date iso formate
     * @param {String} format pass 'dd/mm/yy'
     * @returns formated date in string according to formate passed
     * mostaly used in project module
     */
    dateToFormat(date, format) {
        try {
            date = date.toString()
            const dateobject = new Date(date)
            if (format === "dd/mm/yyyy") {
                const dd = String(dateobject.getDate()).padStart(2, '0')
                const mm = String(dateobject.getMonth() + 1).padStart(2, '0')
                const yyyy = dateobject.getFullYear()
                if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
                    date = '-'
                }
                else {
                    date = dd + '/' + mm + '/' + yyyy
                }
            }
            else {
                const dd = String(dateobject.getDate()).padStart(2, '0')
                const mm = dateobject.getMonth()
                const yyyy = dateobject.getFullYear()
                if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
                    date = '-'
                }
                else {
                    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                    date = dd + ' ' + months[mm] + ', ' + yyyy
                }
            }
        }
        catch {
            date = '-'
        }

        return date
    }

    getDateFormats(date, format = this.getCookiesData("loginInfo").dateformat) {
        try {
            date = date.toString()
            const dateobj = new Date(date)
            const year = dateobj.getFullYear()  // 2022
            const month = dateobj.getMonth() + 1  // 9
            const day = dateobj.getDate()      // 1
            const yy = year.toString().slice(-2) || ''; // 22
            const mm = month.toString().padStart(2, '0') || ''; // 09
            const dd = day.toString().padStart(2, '0') || '';   // 01
            const shortmonth = dateobj.toLocaleString('default', { month: 'short' }) || ''; // Sep

            // if(year == 'NaN'){
            //     alert('NaN')
            // }

            if (format === 1) {
                date = (year || '') + "-" + (mm || '') + "-" + (dd || '') //  2022-09-01
            }
            else if (format === 2) {
                date = month + "/" + day + "/" + year  //  9/1/2022
            }
            else if (format === 3) {
                date = month + "/" + day + "/" + yy
            }
            else if (format === 4) {
                date = dd + "-" + shortmonth + "-" + yy
            }
            else if (format === 5) {
                date = yy + "/" + mm + "/" + dd
            }
            else if (format === 6) {
                date = mm + "/" + dd + "/" + yy
            }
            else if (format === 7) {
                date = dd + "/" + mm + "/" + year
            }
            else if (format === 8) {
                date = shortmonth + "-" + year
            }
            else if (format === 9) {
                date = dd + " " + shortmonth + "," + year
            }
        }
        catch {
            date = "Invalid Date"
        }
        return date
    }

    /**
     * convert mili sec to diff time strinfg formate
     * @param {startDate} date iso formate
     * @param {endDate} date iso formate
     * @param {includeDate} number 1: for include strat date 2 : include end date
     * @returns returns all dates[] between two dates
     * getDatesInRange('2023-06-22T00:00:00.000Z', '2023-06-30T00:00:00.000Z')
     */
    //get all dates [] between two dates
    getDatesInRange(startDate, endDate, includeDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        const date = new Date(startDate.getTime());
        const dates = []

        if (includeDate == 2 || includeDate == 3) {
            date.setDate(date.getDate() + 1);
        }

        //  include start date
        if (includeDate == 1) {
            while (date < endDate) {
                dates.push({ date: new Date(date) });
                date.setDate(date.getDate() + 1);
            }
        }
        // include end date
        else if (includeDate == 2) {
            while (date <= endDate) {
                dates.push({ date: new Date(date) });
                date.setDate(date.getDate() + 1);
            }
        }
        // exclude start date &  end date
        else if (includeDate == 3) {
            while (date < endDate) {
                dates.push({ date: new Date(date) });
                date.setDate(date.getDate() + 1);
            }
        }
        // include start date and date
        else {
            while (date <= endDate) {
                dates.push({ date: new Date(date) });
                date.setDate(date.getDate() + 1);
            }
        }
        return dates
    }

    // NOT IN USE
    getMonthFormat(month) {
        month = month.toString()
        const dateobj = new Date(month)
        const year = dateobj.getFullYear()  // 2022
        const mm = dateobj.getMonth() + 1
        const shortmonth = dateobj.toLocaleString('default', { mm: 'short' });

        month = shortmonth + "-" + year
        return month
    }


    getTimeFormat(time, format = this.getCookiesData("loginInfo").timeformat) {
        try {
            time = time.toString()
            const timeobj = new Date(time)
            let hour = timeobj.getHours()
            const minute = timeobj.getMinutes().toString().padStart(2, '0')

            if (format === 1) {
                time = hour + ":" + minute
                const ampm = hour >= 12 ? 'PM' : 'AM'
                hour = hour % 12
                hour = hour ? hour : 12
                time = hour + ":" + minute + " " + ampm
            }
            else if (format === 2) {
                time = hour + ":" + minute
            }
        }
        catch {
            time = "Invalid Time"
        }
        return time
    }

    /**
     * get time in hh : mm AM / PM format
     * @param {Date Object or String} time 
     * @param {*} format not in use
     * @returns string in hh : mm AM / PM format
     */
    timeToFormat(time, format) {
        try {
            time = time.toString()
            const timeobject = new Date(time)
            var hh = timeobject.getHours()
            const am_pm = (hh > 12 ? 'PM' : 'AM')
            hh = (hh > 12 ? hh - 12 : hh)
            hh = String(hh).padStart(2, '0')
            const mm = String(timeobject.getMinutes()).padStart(2, '0')
            const ss = String(timeobject.getSeconds()).padStart(2, '0')
            if (isNaN(hh) || isNaN(mm) || isNaN(ss)) {
                time = '-'
            }
            else {
                time = hh + ":" + mm + " " + am_pm
            }
        }
        catch {
            time = '-'
        }

        return time
    }

    // NOT IN USE
    // harsh 8-2-2023
    differenceTwoTime(startTime, endTime) {
        const start = new Date(startTime)
        const end = new Date(endTime)
        // const h = Math.floor(((end-start) / (1000 * 60 * 60)) % 24)
        const h = (((end - start) / (1000 * 60 * 60)) % 24).toFixed(2)
        return h ? h : ''
    }

    // harsh 9-1-2023 start
    /**
     * convert string to hh : mm : ss time format
     * @param {String} LogDate in yyyy-mm-dd hh:mm:ss format
     * @param {Number} method 1 for hh : mm : ss , 2 for hh : mm : ss AM/PM
     * @returns string in hh : mm : ss formats
     */
    convertToIsoTime = (LogDate, method = 1) => {
        var timeZoneDifference = (new Date().getTimezoneOffset()) * (-1)
        var logdate = LogDate
        var y = logdate?.substring(0, 4);
        var m = logdate?.substring(5, 7);
        var d = logdate?.substring(8, 10);
        var h = parseInt(logdate?.substring(11, 13));
        var totalmin = parseInt(logdate?.substring(14, 16)) + (h * 60) + timeZoneDifference;
        var s = logdate?.substring(17, 19)
        var min1 = (totalmin / 60).toFixed(2)

        if (parseInt(min1) < 10) {
            min1 = "0" + min1
        }
        var leftmin = totalmin - min1.slice(0, 2) * 60
        var logDate = new Date(y, m, d, totalmin / 60, leftmin, s)

        let hour = new Date(logDate).getHours()
        let minutes = new Date(logDate).getMinutes()
        let second = new Date(logDate).getSeconds()
        let ampm

        if (minutes < 10) {
            minutes = "0" + minutes
        }
        if (second < 10) {
            second = "0" + second
        }

        if (method === 1) {
            if (hour < 10) {
                hour = "0" + hour
            }
            return `${hour} : ${minutes} : ${second}`
        }
        else if (method === 2) {
            if (hour > 12) {
                hour -= 12
                ampm = "PM"
            } else if (hour === 12) {
                hour = 12
                ampm = "PM"
            }
            else {
                hour = hour
                ampm = "AM"
            }
            return `${hour} : ${minutes} ${ampm}`
        }
    }
    // harsh 9-1-2023 end


    /**
     * convert string to date object
     * @param {String} string in 'yyyy-mm-dd' format
     * @returns Date Object
     */
    stringToDate(string) {
        var date
        try {
            date = new Date(string)
            if (date.toString() === 'Invalid Date')
                date = undefined
        }
        catch (e) {
            console.error(e)
            date = undefined
        }
        return date
    }

    // harsh 21-2-2021 
    // get past or future date from today
    /**
     * 
     * @param {Date Object , String 'yyyy-mm-dd'} date new Date() only
     * @param {number} days 
     * @returns 
     */
    getPastFutureDate(date, days) {
        var cdate = new Date(date)
        cdate.setDate(cdate.getDate() + (days))
        return cdate
    }

    /**
     * compare dates ignoring time 
     * @param {Date Object , String 'yyyy-mm-dd'} startDate 
     * @param {Date Object , String 'yyyy-mm-dd'} endDate 
     * @returns if start date is less then end date then returns true else false
     */
    compareDates(startDate, endDate) {
        let startdate = new Date(startDate)
        let enddate = new Date(endDate)

        if (startdate.getFullYear() < enddate.getFullYear()) {
            return true
        }
        else if (startdate.getFullYear() == enddate.getFullYear()) {
            if (startdate.getMonth() < enddate.getMonth()) {
                return true
            }
            else if (startdate.getMonth() === enddate.getMonth()) {
                if (startdate.getDate() <= enddate.getDate())
                    return true
                else
                    return false
            }
            else {
                return false
            }
        } return false
    }

    /**
     * compare time ignoring date
     * @param {Date Object , String 'yyyy-mm-dd'} startDate 
     * @param {Date Object , String 'yyyy-mm-dd'} endDate 
     * @returns if start time is less then end time then returns true else false
     */
    compareTimes(startDate, endDate) {
        let startdate = new Date(startDate)
        let enddate = new Date(endDate)

        if (startdate.getHours() < enddate.getHours()) {
            return true
        } else if (startdate.getHours() > enddate.getHours()) {
            return false
        }
        else if (startdate.getHours() == enddate.getHours()) {
            if (startdate.getMinutes() < enddate.getMinutes()) {
                return true
            }
            else {
                return false
            }
        } return false
    }

    // NOT IN USE
    ConvertDateTimeToDate(datetime) {
        return new Date(datetime).getFullYear() + '-' + (((new Date(datetime) + 1) < 10) ? '0' : '') + (new Date(datetime).getMonth() + 1) + '-' + ((new Date(datetime).getDate() < 10) ? '0' : '') + new Date(datetime).getDate()
    }

    // NOT IN USE
    // juhil - 5-11-22
    ConvertDateTimeToTime(datetime) {
        return ((new Date(datetime).getHours() < 10) ? '0' : '') + new Date(datetime).getHours() + ":" + ((new Date(datetime).getMinutes() < 10) ? '0' : '') + new Date(datetime).getMinutes() + ":" + ((new Date(datetime).getSeconds() < 10) ? '0' : '') + new Date(datetime).getSeconds()
    }
    // end

    // harsh 24-1-2023
    /**
     * convert minutes and hours to miliseconds
     * @param {String  , Number} minute 
     * @param {String  , Number} hour 
     * @returns miliseconds
     */
    ConvertTimeToMill = (minute, hour) => {
        const m = minute ? (parseInt(minute)) * 60000 : 0
        const h = hour ? (parseInt(hour)) * 3600000 : 0
        const milliseconds = m + h
        return milliseconds
    }

    /**
     * get total number of days in month or last date of month
     * @param {Date Object , ISO String , String 'yyyy-mm-dd' } date 
     * @returns total number of days in month or last date of month
     */
    getDaysInMonth(date) { //ISO Date
        let year = new Date(date).getFullYear()
        let month = new Date(date).getMonth() + 1

        return new Date(year, month, 0).getDate()
    }

    /**
     * get first day of month 
     * @param {Date Object , ISO String , String 'yyyy-mm-dd'} date 
     * @returns first day of month in Date Object
     */
    getFirstDayOfMonth(date) { // ISO Date
        let year = new Date(date).getFullYear()
        let month = new Date(date).getMonth()
        let day = 1
        return new Date(year, month, day).toISOString()
    }

    /**
     * get last day of month
     * @param {Date Object , ISO String , String 'yyyy-mm-dd'} date 
     * @returns last day of month in Date Object
     */
    getLastDayOfMonth(date) { //ISO Date
        let year = new Date(date).getFullYear()
        let month = new Date(date).getMonth()
        let day = parseInt(this.getDaysInMonth(date))

        return new Date(year, month, day, 23, 59, 59).toISOString()
    }

    /**
     * add minutes to time 
     * @param {Date Object , ISO String , String 'yyyy-mm-dd'} date 
     * @param {Number} minute 
     * @returns updated Date Object
     */
    addMinuteToTime(date, minute) { //ISO date
        const time = new Date(date)
        const temp = new Date(time.getTime() + minute * 60000)
        return new Date(temp.toString()).toISOString()
    }

    // NOT IN USE
    // hemang jani
    // new Date
    // DateToISoFormate(date){

    //     let year = date.getFullYear()
    //     let month = date.getMonth() + 1
    //     let day = date.getDate()
    //     let hr = date.getHours()
    //     let min = date.getMinutes()
    //     let sec = '00'

    //     return year+'-'+month+'-'+day+'T'+hr+':'+min+':'+sec+'.000Z'
    // }


    /************************************************************* DATE AND TIME METHODS END ************************************************************************************ */


    /**
     * convert string to int number
     * @param {String} value 
     * @returns number
     */
    stringToInt(value) {
        value = Number(value)
        return isNaN(value) ? 0 : value
    }

    /************************************************************* ARRAY AND OBJECT METHODS START ************************************************************************************ */
    /**
     * to get random elemets from array
     * @param {Array} arr 
     * @param {Number} n number to get n elements
     * @returns new randomly generated n length array
     */
    getUniqueElementsFromArray(arr, n = 1) {
        arr = arr.sort(() => 0.5 - Math.random())
        // let m = arr.length
        // while (m) {
        //     const i = Math.floor(Math.random() * m--);
        //     [arr[m], arr[i]] = [arr[i], arr[m]]
        // }
        return arr.slice(0, n)
    }

    /**
     * get Object from array of object
     * pass array of object  , key to match from object and value
     * @param {Array} arr Array of Object
     * @param {Strig} key 
     * @param {Any} value 
     * @returns Object if found or undefined
     */
    getobjectfromarray(arr, key, value) {
        try {
            return arr.find(o => o[key] == value)
        }
        catch (e) {
            return undefined
        }
    }

    /**
     * get Object from array of object from multiple keys and value
     * @param {Array} arr Array of Object 
     * @param {String} key1 
     * @param {String} key2 
     * @param {Any} value1 
     * @param {Any} value2 
     * @returns Object if found or undefined
     */
    getobjectfromarraydoublekey(arr, key1, key2, value1, value2) {
        try {
            return arr.find(o => o[key1] === value1 && o[key2] === value2)
        }
        catch {
            return undefined
        }
    }

    /**
     * get index from array of object
     * @param {Array} arr Array of Object 
     * @param {String} key 
     * @param {Any} value 
     * @returns return index from array of object or -1 if not found
     */
    getindexfromarray(arr, key, value) {
        try {
            return arr.findIndex(o => o[key] === value)
        }
        catch {
            return -1
        }
    }

    /**
     * get multiple indexes from array of object
     * @param {Array} arr Array of Object 
     * @param {String} key 
     * @param {Any} value 
     * @returns return array of indexes from array of object or [] if not found
     */
    getmultipleindexfromarray(arr, key, value) {
        try {
            let indexes = arr.reduce(function (ind, obj, i) {
                if (obj[key] == value)
                    ind.push(i)
                return ind
            }, [])

            return indexes
        }
        catch {
            return []
        }
    }


    /**
     * get index from array of object multiple key and value
     * @param {Array} arr Array of Object
     * @param {String} key1 
     * @param {String} key2 
     * @param {Any} value1 
     * @param {Any} value2 
     * @returns index from array of object or -1 if not found
     */
    getindexfromarraydoublekey(arr, key1, key2, value1, value2) {
        try {
            return arr.findIndex(o => o[key1] === value1 && o[key2] === value2)
        }
        catch {
            return -1
        }
    }

    // hemang jani changed 19/01/23
    /**
     * move item in array
     * @param {Array} arr 
     * @param {*} index 
     * @param {*} newItem 
     * @returns updated arry
     */
    replaceItemInArry(arr, index, newItem) {
        arr.splice(index, 1, newItem)
        return arr
    }

    /**
     * get multiple objects in new array from array of object
     * @param {Array} arr Array of Object
     * @param {String} key 
     * @param {Any} value 
     * @returns new array 
     */
    getmultipleobjectsfromarray(arr, key, value) {
        return arr.filter(o => o[key] === value)
    }

    // hemang 11/1/23
    /**
     * get multiple objects in new array from array of object using multiple key and vaue
     * @param {Array} arr Arrya of Object 
     * @param {String} key1 
     * @param {String} key2 
     * @param {Any} value1 
     * @param {Any} value2 
     * @returns new array 
     */
    getmultipleobjectsfromarraydoublekey(arr, key1, key2, value1, value2) {
        return arr.filter(o => o[key1] === value1 && o[key2] === value2)
    }

    // NOT IN USE
    removeDuplicateObjectsFromArray(arr) {
        return arr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.place === value.place && t.name === value.name
            ))
        )
    }

    /**
     * 
     * @param {Array} arr required
     * @param {String} key1 required
     * @param {String} key2 for remove duplicates by considering depth key
     * @returns array with unique values
     */
    removeDuplicateFromArray(arr, key1, key2) {
        try {
            let uniqueArr
            if (key2) {
                uniqueArr = [...new Map(arr.map((item) => [item[key1][key2], item])).values(),]
            }
            else {
                uniqueArr = [...new Map(arr.map((item) => [item[key1], item])).values(),]
            }
            return uniqueArr
        }
        catch {
            return arr
        }
    }

    /************************************************************* ARRAY AND OBJECT METHODS END ************************************************************************************ */
    // works for frist depth only
    // for multiple depth use json.parse & json.stringify
    getCopy(element) {
        try {
            return element.map(o => Object.assign({}, o))
        }
        catch {
            return Object.assign({}, element)
        }
    }
    /*******************************************************************************replace Base64 to S3URL START************************************************************************************************* */
    //replace Base64 to S3URL
    replaceBase64WithS3URL = async (content) => {
        // Regular expression to match base64 strings
        const base64Regex = /src="data:image\/[^;]+;base64,([^"]+)"/g
        let updatedContent = content
        let match
        // Iterate through matches and replace base64 strings with S3 URLs
        while ((match = base64Regex.exec(content)) !== null) {
            var BufferObj = await IISMethods.base64ToBuffer(match[0])
            const buffer = Buffer.from(BufferObj.bufferData, 'binary')

            let s3ImageUrl = await IISMethods.UploadTOS3(buffer, `.${BufferObj['filetype']}`, BufferObj['ContentType'])

            updatedContent = updatedContent.replace(match[0], `src="${s3ImageUrl}"`)
            updatedContent = updatedContent.replace(/data-file-name="[^"]*"/g, '')  // Replace data-file-name  attribute with an empty string
        }

        // Replace class attribute with an empty string
        updatedContent = updatedContent?.replace(/<img\b[^>]*\bclass\s*=\s*['"][^'"]*['"][^>]*>/gi, (match) => {
            return match.replace(/\bclass\s*=\s*['"][^'"]*['"]/gi, '')
        })

        return updatedContent
    }
    /*******************************************************************************replace Base64 to S3URL END************************************************************************************************* */

    /************************************************************* react-toastify METHODS START ************************************************************************************ */
    /**
     * used for create toast for notificaiton only
     * @param {Any} message 
     * @param {Any} status 
     * @param {Function} onClick 
     */
    notificationnotify(message, status, onClick) {
        //Display notifications only when the document is focused
        if (document.hasFocus()) {
            if (!status && resBody.status === 200) {
                toast.success(
                    <>
                        {/* <h1 className='notification-success-title notification-title'>Success</h1> */}
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🟢",
                        icon: <img src={bellnotification} alt="Success Icon" className='timer-tostify-img' />,
                        // autoClose: 3000,
                        onClick: onClick,
                        backgroundImage: `url('../assets/images/notification-bg.png')`,
                        bodyClassName: 'timetracking-tostify p-0',
                    })
            }
            else if (status === 1) {
                toast.warn(
                    <>
                        <h1 className='notification-title notification-warning-title'>Warning</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🟡",
                        icon: <img src={notificationWarning} alt="Warning Icon" />,
                        // autoClose: 3000,
                        bodyClassName: 'warning-notification-body p-0',
                        onClick: onClick
                    })
            }
            else {
                toast.error(
                    <>
                        <h1 className='notification-title notification-error-title'>Error</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🔴",
                        icon: <img src={notificationError} alt="Error Icon" />,
                        // autoClose: 3000,
                        bodyClassName: 'error-notification-body p-0',
                        onClick: onClick
                    })
            }
        }
    }

    /**
     * used for create toast in CRUD API requests
     * @param {Any} message 
     * @param {Number} status 
     */
    notify(message, status) {
        //Display notifications only when the document is focused
        if (document.hasFocus()) {
            if (!status && resBody.status === 200) {
                toast.success(
                    <>
                        <h1 className='notification-success-title notification-title'>Success</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        // transition: 'zoom',
                        theme: "light",
                        // position: "top-right",
                        // pauseOnHover: true,
                        // hideProgressBar: false,
                        // icon: "🟢",
                        // autoClose: 3000,
                        // progress: undefined, 
                        // icon: <img src={notificationbg} alt="Success bg" className='notification-bg-img'/> ,
                        icon: <img src={notificationSuccess} alt="Success Icon" />,
                        bodyStyle: { "backgroundImage": `url('../assets/images/notification-bg.png')` },
                        backgroundImage: `url('../assets/images/notification-bg.png')`,
                        bodyClassName: 'success-tostify p-0',
                    })
            }
            else if (status === 1) {
                toast.warn(
                    <>
                        <h1 className='notification-title notification-warning-title'>Warning</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🟡",
                        // autoClose: false,
                        icon: <img src={notificationWarning} alt="Warning Icon" />,
                        bodyClassName: 'warning-notification-body p-0',
                        containerId: "gautam"
                    })
            }
            else {
                toast.error(
                    <>
                        <h1 className='notification-title notification-error-title'>Error</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🔴" ,
                        // autoClose: false,
                        // toastStyle : {"backgroundImage" : `url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80')`},
                        icon: <img src={notificationError} alt="Error Icon" />,
                        // bodyStyle : {"backgroundImage" : `url('../assets/images/moodbg.png')`},
                        toastStyle: { "backgroundImage": `url(${notificationErrorbg})` },
                        bodyClassName: 'error-notification-body p-0',
                    })
            }
        }
    }

    /**
     * for create local toast  without req , res 
     * use for validaiton and other toast generated from frontend 
     * @param {*} message 
     * @param {*} status 
     */
    localnotify(message, status) {
        //Display notifications only when the document is focused
        if (document.hasFocus()) {
            if (status == 1) {
                toast.success(
                    <>
                        <h1 className='notification-success-title notification-title'>Success</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🟢",
                        // autoClose: false,
                        icon: <img src={notificationSuccess} alt="Success Icon" />,
                        bodyClassName: 'p-0',
                    })
            }
            else if (status == 2) {
                toast.warn(
                    <>
                        <h1 className='notification-title notification-warning-title'>Warning</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🟡",
                        // autoClose: false,
                        icon: <img src={notificationWarning} alt="Warning Icon" />,
                        bodyClassName: 'warning-notification-body p-0',

                    })
            }
            else {
                toast.error(
                    <>
                        <h1 className='notification-title notification-error-title'>Error</h1>
                        <h6 className='notification-description'>{message}</h6>
                    </>,
                    {
                        theme: "light",
                        // icon: "🔴",
                        // autoClose: false,
                        bodyClassName: 'error-notification-body p-0',
                        icon: <img src={notificationError} alt="Error Icon" />,
                    })
            }
        }
    }

    /************************************************************* react-toastify METHODS END ************************************************************************************ */

    /************************************************************* log out , token expiry and session expiry START ************************************************************************************ */
    async logout() {
        // var subdomainname
        // try {
        //     subdomainname = this.getCookiesData("loginInfo").subdomainname
        // }
        // catch {
        //     subdomainname = 'IIS'
        // }

        //logout api called JENIL 29/5/2023 11:44 AM
        let reqData = {
            "macaddress": this.getCookiesData("loginInfo")?.fbtoken
        }
        const url = Config.weburl + 'logout'
        await setReqParams(url, reqData)
        await this.addData("addlogout")

        // unsubscribe from socket 
        socket.emit("unsubscribe", this.getCookiesData("loginInfo")?.uid)
        // clear cookie and localstorage
        this.clearLocalData("userRights")
        this.clearLocalData("menuData")
        this.clearLocalData('projectsData')
        this.clearLocalData('iconExtension')
        this.clearLocalData('isClose')
        this.clearLocalData('s3jwttokens')
        this.clearCookiesData("loginInfo")
        // window.location.href = `/login?q=${subdomainname}&targeturl=${window.location.pathname.replace('/', '')}`

        // clear session storage
        this.clearSessionData("sessionData")

        // hemnag jani 30/01/23
        if (Config.getServermode() == 'prod') {
            // window.location.href = `/login?targeturl=${window.location.pathname.replace('/', '')}`
            window.location.href = `/${window.location.pathname.replace('/', '')}${window.location.search?.length ? window.location.search : ``}`
        }
        else {
            // window.location.href = `/login?q=${subdomainname}&targeturl=${window.location.pathname.replace('/', '')}`
            // window.location.href = `/${window.location.pathname.replace('/', '')}${window.location.search?.length ? window.location.search : `?q=${subdomainname}` }`
            window.location.href = `/${window.location.pathname.replace('/', '')}${window.location.search?.length ? window.location.search : ``}`
        }
    }

    async tokenExpiry(info) {
        if (resHeaders.key && resHeaders.unqkey) {
            var loginInfo = info ? info : this.getCookiesData("loginInfo")

            loginInfo.token = resHeaders.key
            loginInfo.unqkey = resHeaders.unqkey
            if (info) {
                return loginInfo
            }
            else {
                this.setCookiesData("loginInfo", loginInfo)
            }

            if (loginInfo.fbtoken) {
                await this.addDeviceToken(resHeaders.key)
            }
        }
    }

    // add device token (for firebase notification)
    async addDeviceToken() {
        let loginInfo = this.getCookiesData("loginInfo")
        let url = Config.weburl + 'devicetoken/add'
        const useraction = 'adddevicedata'

        let reqData = {}
        reqData['appversion'] = process.env.REACT_APP_VERSION
        reqData['deviceid'] = loginInfo.fbtoken
        reqData['os'] = this.getOSDetail()
        reqData['osversion'] = window.navigator.appVersion
        reqData['macaddress'] = loginInfo.fbtoken //macaddress same as device id JENIL 29/5/2023 11:42 AM
        reqData['devicemodelname'] = 'Windows'

        await setReqParams(url, reqData)
        await this.addData(useraction, false)

        if (resBody.status === 200) { }
    }


    sessionExpiry() {
        if (resBody.status === 401 && resBody.message === Config.getErrmsg()['invalidtoken']) {
            this.logout()
        }
    }
    /************************************************************* log out , token expiry and session expiry END ************************************************************************************ */

    // 7/11/2022 Hemang Jani
    isSuperAdmin = () => {
        return IISMethods.getCookiesData("loginInfo")?.userroleid === Config.adminutype
    }

    // 17-03-2023 Shruti
    isCustomer = () => {
        return IISMethods.getCookiesData("loginInfo")?.iscustomer === 1
    }
    // 7/11/2022 Hemang Jani

    // to check for default id
    isDefaultUserRole(userroleid) {
        return userroleid == Config.customeruserroleid || userroleid == Config.partneruserroleid || userroleid == Config.supersdminuserroleid
    }

    // to get User's Type by id
    getUserType(usertypeid = IISMethods.getCookiesData("loginInfo")?.usertypeid) {

        if (usertypeid == Config.usertypes['partner']) {
            return 'partner'
        }
        else if (usertypeid == Config.usertypes['customer']) {
            return 'customer'
        }
        else if (usertypeid == Config.usertypes['adminuser']) {
            return 'adminuser'
        }
    }


    /************************************************************* FILE UPLOAD , FILE TYPES VALIDATIONS METHODS START ************************************************************************************ */
    /**
     * convert file binary data (e.target.file) to base64 srting
     * @param {File} file e.target.file
     * @returns base64 string
     */
    async fileToBase64(file) {
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        }

        var base64File

        await getBase64(file)
            .then(data => base64File = data)
            .catch(error => base64File = '')

        return base64File
    }

    // used in apimanagercontroller
    /**
     * convert string base64
     * @param {String} value 
     * @returns 
     */
    stringToBase64(value) {
        return Buffer.from(value).toString('base64')
    }

    //get timestamp string
    GetTimestamp() {
        return Date.now()
    }

    // 9/12/2022 hemang
    /**
     * check file types from file binary data (e.target.file)
     * @param {File} file e.target.file
     * @param {Array} allowedFiles array of allowded file extentions
     * @returns true or false
     */
    checkFiletype(file, allowedFiles) {
        try {
            let fileName = this.getFileName(file)
            let fileExtension = this.getFileExtention(fileName)
            var regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$")
            return regex.test(" iis" + fileExtension.toLowerCase())
        }
        catch (e) {
            return false
        }
    }

    /**
     * get file extention from file name
     * @param {String} fileName file name with extension
     * @returns extension from file name
     * for example abc.pdf returns pdf
     */
    getFileExtention(fileName) {
        var fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
        try {
            return fileName.match(fileExtensionPattern)[0]
        }
        catch { }
    }

    /**
     * get file name form file binary data (e.target.file) or s3 bucket url
     * @param {File} file e.target.file
     * @returns file name 
     */
    getFileName(file) {
        let filename = ''
        try {
            if (typeof (file) == 'string') {
                filename = file.substring(file.lastIndexOf('/'), file.length)
            }
            else
                filename = file.name
        }
        catch { }

        return filename
    }

    /**
     * get url form S3 accroding to file extension form local storage
     * used in UI to show icon in html according to file extension
     * @param {File} file e.target.file
     * @returns img url for respective file extension
     */
    getFileExtentionIcon(file) {
        let fileName, fileExtension
        try {
            if (!this.isBase64(file)) {
                fileName = this.getFileName(file)
            }

            if (this.isBase64(file)) {
                fileExtension = file.substring(file.indexOf('/') + 1, file.indexOf(';base64')) || ''
                fileExtension = "." + fileExtension
            }
            else
                fileExtension = this.getFileExtention(fileName)

            let listOfIconExtensions = this.getLocalData("iconExtension")
            let icon = {}
            icon = listOfIconExtensions.find(o => o['extensionname'].includes(fileExtension) && fileExtension?.length)

            return icon.extensionicon
        }
        catch (e) {
            return Config.getDefaulImageUploadUrl()
        }
    }

    /**
     * check if file data is in base 64
     * @param {File} file e.target.file
     * @returns true or false
     */
    isBase64(file) {
        try {
            return file.startsWith("data:") && (typeof file === 'string')
        }
        catch { return true }
    }

    // array of all extions available
    getAllFiles() {
        return [...this.getAllowdedDocuments('*'), ...this.getAllowdedImage('*'), ...this.getAllowdedCodeFiles('*')]
    }

    // get code file extensions .php , .js etc
    getAllowdedCodeFiles(extensions) {
        if (extensions == '*') {
            return Object.values(allowdedCodeFiles)
        }
        else {
            let arry = []
            extensions?.map(extension => {
                try {
                    arry.push(allowdedCodeFiles[extension])
                }
                catch { }
            })
            return arry
        }
    }

    // array of allowded documents (pdf, word , ppt , excel) extensions
    getAllowdedDocuments(documents) {
        let arry = []
        if (documents == '*') {
            Object.values(allowdedDocuments)?.map(document => {
                try {
                    if (Array.isArray(document)) {
                        arry = [...arry, ...document]
                    }
                    else {
                        arry.push(document)
                    }
                }
                catch (e) { }
            })
            return arry
        }
        else {
            documents?.map(document => {
                try {
                    if (Array.isArray(allowdedDocuments[document])) {
                        arry = [...arry, ...allowdedDocuments[document]]
                    }
                    else {
                        arry.push(allowdedDocuments[document])
                    }
                }
                catch { }
            })
            return arry
        }
    }

    // array of allowded img extensions
    getAllowdedImage(extensions) {
        if (extensions == '*') {
            return Object.values(allowdedImage)
        }
        else {
            let arry = []
            extensions?.map(extension => {
                try {
                    arry.push(allowdedImage[extension])
                }
                catch { }
            })
            return arry
        }
    }

    /**
     * 
     * @param {Number} value value of file size 
     * @param {String} unit Kb or Mb --> value you entered
     * @returns bytes
     */

    //convert KB or MB into bytes
    convertToBytes(value, unit) {
        const kilobyte = 1024
        const megabyte = kilobyte * 1024

        if (unit.toUpperCase() === 'KB') {
            return value * kilobyte
        } else if (unit.toUpperCase() === 'MB') {
            return value * megabyte
        }
    }

    // 9/12/2022 hemang

    /************************************************************* FILE UPLOAD , FILE TYPES VALIDATIONS METHODS END ************************************************************************************ */

    /**
     * convert file binary data (e.target.file) to formData 
     * @param {File} file e.target.file
     * @returns binary data into formData
     * used in uploading images . files, video , audio
     */
    fileToFormData(file) {
        let bodyFormData = new FormData()
        bodyFormData.append('files', file)
        return bodyFormData
    }
    // convert to formdata

    getAxiosToken() {
        let cancelToken
        cancelToken = axios.CancelToken.source()
        return cancelToken
    }

    async authenticate(Email, Password) {
        return new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: Email,
                Pool: this.userpool()
            })

            const authDetails = new AuthenticationDetails({
                Username: Email,
                Password: Password,
            })

            user.authenticateUser(authDetails, {
                onSuccess: (result) => {
                    resolve(result)
                    let user = this.userpool().getCurrentUser()
                    if (user) {
                        user.getSession((err, session) => {
                            if (err) {
                                console.error(err)
                            }
                            else {
                                s3sessoin = session
                                IISMethods.setLocalData("s3jwttokens", session)
                            }
                        })
                    }
                    else {
                        console.error("User Not Found ----> Congnito User")
                    }
                },
                onFailure: (err) => {
                    console.error("login failed", err)
                    reject(err)
                }
            });
        });
    }


    logoutCognito() {
        let user = this.userpool().getCurrentUser()
        s3sessoin = {}
        user.signOut()
    }

    userpool() {
        const poolData = {
            UserPoolId: this.getCurrentState().USER_POOL_ID,
            ClientId: this.getCurrentState().POOL_CLIENT_ID,
            IdentityPollId: this.getCurrentState().IDENTITY_POOL_ID,
        }

        return new CognitoUserPool(poolData)
    }


    //extention which we want to passed static like .pdf
    async FileUpload(file, extension, contenttype) {
        const cognito_login_url = `cognito-idp.${Config.cognito_user_pool_region}.amazonaws.com/${this.getCurrentState().USER_POOL_ID}`
        // set credential before upload
        AWSService.config.credentials = new AWSService.CognitoIdentityCredentials({
            IdentityPoolId: this.getCurrentState().IDENTITY_POOL_ID,
            Logins: {
                [cognito_login_url]: s3sessoin.idToken.jwtToken
            }
        },
            {
                // identity pool region
                region: Config.cognito_user_pool_region,
            }
        )

        const s3 = new AWSService.S3({
            params: { Bucket: this.getCurrentState().S3_BUCKET_NAME }
        })

        let fileName = extension ? "" : this.getFileName(file)
        let fileExtension = extension ? extension : this.getFileExtention(fileName)

        const timestamp = IISMethods.GetTimestamp()
        const key = 'Bizcompass/temp/' + timestamp + fileExtension

        // not wroking
        const conditions = [
            ["content-length-range", 0, 1000]
        ]

        let file_url = ''

        // var upload = s3.putObject({
        //     Key : key,
        //     Bucket : this.getCurrentState().S3_BUCKET_NAME,
        //     Body : file,
        //     ACL : 'public-read',
        //     // Conditions :conditions
        // }).promise()

        // await upload.then((err, data) => {
        //     console.log(err);
        //     console.log(data)
        //     alert("File uploaded successfully.");
        // });

        var upload
        try {
            upload = await s3.upload(
                {
                    Key: key,
                    Bucket: this.getCurrentState().S3_BUCKET_NAME,
                    Body: file,
                    ACL: 'public-read',
                    ContentType: contenttype ? contenttype : file.type
                }
            ).on("httpUploadProgress", (evt) => {
                console.log(
                    "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                )
            }).promise()

            file_url = upload
            return file_url.Location
        }
        catch (error) {
            console.error(error)
            return null
        }
    }

    // async refreshTOken(){
    //     // let token = new CognitoRefreshToken({RefreshToken : s3sessoin.refreshToke.token})

    //     let token = new CognitoRefreshToken({RefreshToken : s3sessoin.refreshToken.token})

    //     const user=new CognitoUser({
    //         Username: this.getCurrentState().POOL_NAME,
    //         Pool: this.userpool()
    //     });

    //     user.refreshSession(token,async (err,session)=>{
    //         if (err) {
    //             console.error(err)
    //         }
    //         else {
    //             IISMethods.setLocalData("s3jwttokens",session)
    //             s3sessoin = session
    //             await IISMethods.UploadTOS3(file)
    //         }
    //     })
    // }

    /**
     * to refresh cognito tokrn on expiry
     * @param {Cognito Token} decodedToken 
     * @returns updated token
     */
    async refreshTOken(decodedToken) {
        let currentDate = new Date()
        if (decodedToken?.exp * 1000 < currentDate.getTime()) {
            // access token expires
            let token = new CognitoRefreshToken({ RefreshToken: s3sessoin.refreshToken.token })

            const user = new CognitoUser({
                Username: this.getCurrentState().POOL_NAME,
                Pool: this.userpool()
            })

            let promise = new Promise(
                function (resolve, reject) {
                    user.refreshSession(token, async (err, session) => {
                        if (err) {
                            // error for token re-fresh
                            console.error(err)
                            // when refresh token expires then re-login and update s3session
                            // refresh token expiry ---> 30day(s)
                            await IISMethods.authenticate(IISMethods.getCurrentState().POOL_NAME, IISMethods.getCurrentState().POOL_PASS)
                            reject(err)
                        }
                        else {
                            IISMethods.setLocalData("s3jwttokens", session)
                            s3sessoin = session
                            resolve(s3sessoin)
                        }
                    })
                }
            )
            return promise
        }
    }


    /**
   * conver base64 to buffer
   * @param {base64} base64Data 
   * @returns Buffer datas object
   */

    async base64ToBuffer(base64Data) {
        var base64 = base64Data.split(',')
        var bufferData = Buffer.from(base64[1], 'base64')

        var ContentType = base64[0].split(':')
        ContentType = ContentType[1].split(';')
        ContentType = ContentType[0]

        var filetype = base64[0].split('/')

        filetype = filetype[1].split(';')
        filetype = filetype[0]

        var BufferObj = {
            bufferData: bufferData,
            filetype: filetype,
            ContentType: ContentType
        }
        return BufferObj
    }


    /**
     * upload file to s3
     *  @param {File Binary } reqBody 
     *  returns temp url if file uploads else null
     */
    async UploadTOS3(file, extension, contenttype) {
        let decodedToken

        // decode jwt token
        try {
            decodedToken = jwtDecode(s3sessoin?.idToken?.jwtToken || '')
        }
        // if token undefined
        catch {
            await this.authenticate(this.getCurrentState().POOL_NAME, this.getCurrentState().POOL_PASS)
            return await IISMethods.UploadTOS3(file, extension, contenttype)
            
        }
        let currentDate = new Date()

        // check session expiry
        if (decodedToken?.exp * 1000 < currentDate.getTime()) {
            // access token expires
            let promise
            try {
                promise = await this.refreshTOken(decodedToken)
            }
            catch (e) { }
            return await this.FileUpload(file, extension, contenttype)

        }
        else {
            return await this.FileUpload(file, extension, contenttype)
        }

        // old code to upload to s3
        // const AWS_REGION = "ap-south-1"
        // const AWS_SECRET_ACCESS_KEY = "RDC7b1Ibs1XkC7+HjcM1zcglgkTvkcac6I69Brxs"
        // const AWS_ACCESS_KEY = "AKIAY2LOIBVIJHW44QP3"
        // const AWS_BUCKET_NAME = 'bizcompass'

        // // hemang jani
        // var ContentType
        // let fileName = this.getFileName(file)
        // let fileExtension = this.getFileExtention(fileName)
        // const client = new S3Client({
        //     region: AWS_REGION,
        //     credentials: {
        //       accessKeyId: AWS_ACCESS_KEY,
        //       secretAccessKey:AWS_SECRET_ACCESS_KEY,
        //     },
        // })

        // const timestamp =  IISMethods.GetTimestamp()
        // const key = 'Bizcompass/temp/' + timestamp + fileExtension

        // const params = {
        //     Bucket: AWS_BUCKET_NAME ,
        //     Key: key,
        //     Body: file,
        //     ACL : 'public-read', 
        //     ContentType : file.type,
        // }

        // const command = new PutObjectCommand(params);

        // let ImageData
        // let url
        // try{
        //     ImageData = await client.send(command).then(function(data){
        //         return data
        //     })
        //     url = `https://${AWS_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/${key}`
        //     this.localnotify(Config.errmsg.docuploaded,1)
        //     return url
        // }
        // catch(e){
        //     console.error(e)
        //     return null
        // }
    }


    /**
     * upload file to s3 
     * @param {FormData} reqBody 
     * @param {Boolean} showResponseMessage 
     * used in uploading images . files, video , audio
     */
    // async UploadTOS3(reqBody,showResponseMessage = true, showMessageOnError = false , cancelToken = null){
    //     // debugger
    //     const loginInfo = this.getCookiesData("loginInfo")
    //     const url = Config.weburl + 'uploadfile'
    //     let reqheader = {
    //         token: loginInfo.token,
    //         issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
    //         unqkey: loginInfo.unqkey,
    //         uid: loginInfo.uid,
    //         platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
    //         // useraction: useraction,
    //         pagename: this.getpagename(),
    //         subdomainname: loginInfo.subdomainname,
    //         username: loginInfo.firstname + ' ' + loginInfo.lastname,
    //         companyid: loginInfo.companyid,
    //         branchid: loginInfo.branchid,
    //         userroleid: loginInfo.userroleid,
    //         version : Config.version
    //         // for public ip
    //         // publicip : loginInfo.clientpublicip
    //     }

    //     // hemang jani 30/01/23
    //     // encrypt req header
    //     if( Config.encryptdata ){
    //         reqHeaders = {reqheader : this.encryptData(reqheader) } 
    //     }
    //     else{
    //         reqHeaders = {...reqheader}
    //     }

    //     // if(Config.encryptdata ){
    //     //     this.Log('requestname',this.getpagename())
    //     //     this.Log('decryptData')

    //     //     reqBody = {reqbody : this.encryptData(reqBody)}

    //     // }
    //     await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, uploadtos3SuccessCallback.bind(this), uploadtos3ErrorCallback.bind(this),cancelToken)

    //     function uploadtos3SuccessCallback(res) {
    //         if(Config.encryptdata){
    //             try{
    //                 resBody =  JSON.parse(JSON.parse(this.decryptData(res.data)))
    //             }
    //             catch(e){
    //                 resBody = res.data
    //             }

    //             // decrypt res header
    //             // hemang jani 30/01/23
    //             if(res.headers.reqheader){
    //                 resHeaders = JSON.parse(JSON.parse(this.decryptData( res.headers ))) 
    //             }
    //             else{
    //                 resHeaders = res.headers
    //             }
    //         }
    //         else{
    //             resBody = res.data
    //             // hemang jani 30/01/23
    //             resHeaders = res.headers
    //         }
    //     }

    //     function uploadtos3ErrorCallback(error) {
    //         if( Config.encryptdata){
    //             try{
    //                 resBody =  JSON.parse(JSON.parse(this.decryptData( error.response.data)))
    //             }
    //             catch(e){
    //                 resBody = error.response.data
    //             }

    //             if(resBody.updateversion){
    //                 IISMethods.HardRefresh()
    //             }

    //             // decrypt res header
    //             // hemang jani 30/01/23
    //             if(error.response.headers.reqheader){
    //                 resHeaders = JSON.parse(JSON.parse(this.decryptData( error.response.headers )))
    //             }
    //             else{
    //                 resHeaders = error.response.headers
    //             }
    //         }
    //         else{
    //             resBody = error.response.data
    //             if(resBody.updateversion){
    //                 IISMethods.HardRefresh()
    //             }
    //             // hemang jani 30/01/23
    //             resHeaders = error.response.headers
    //         }
    //     }

    //     this.sessionExpiry()
    //     this.tokenExpiry()
    //     if (showResponseMessage || (showMessageOnError && resBody.status !== 200)){
    //         this.notify(resBody.message)
    //     }

    //     return resBody
    // }
    //upload file to s3 

    // for get data by id
    // used in update data logic
    async getDataById(id, url = '', useraction = '', pagename = undefined) {
        let filter = { '_id': id }
        const reqBody = {
            searchtext: '',
            paginationinfo: {
                pageno: 1,
                pagelimit: 20,
                filter: filter,
                sort: {}
            }
        }

        await IISMethods.socketListData(url, reqBody, useraction, undefined, undefined, pagename)
        if (resBody.status === 200) {
            if (resBody.data.length > 0) return resBody.data[0]
            else return {}
        }
        else return {}
    }
    // encrypt
    /**
     * @param {bool} isId when encrypting ids  - uid, openingid
     * @param {bool} idWithoutFilter true when copying openings with active/all filter "61fb6ff57e03cfd119505694&stactive"
     */
    encryptData(data, isId = false, idWithoutFilter = false) {
        const randomiv = this.generateRandomIv()
        const secretKey = CryptoJS.enc.Utf8.parse(this.getCurrentState()['REACT_APP_SECRETKEY'])
        const iv = CryptoJS.enc.Utf8.parse(randomiv)
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, { iv: iv })
        if (isId) {
            if (idWithoutFilter) {
                return encrypted.toString() + randomiv
            }
            else {
                return encrypted.toString() + "=" + randomiv
            }
        }
        else {
            return encrypted.toString() + '.' + randomiv
        }
    }

    // decrypt
    /**
     * @param {bool} isId when decrypting ids - uid, openingid 
     */
    decryptData(data, isId = false) {
        let plaintext
        const secretKey = CryptoJS.enc.Utf8.parse(this.getCurrentState()['REACT_APP_SECRETKEY'])
        // const secretKey = CryptoJS.enc.Hex.parse(Config.getSekretkey())
        let ciphertext
        if (isId) {
            const splitIndex = data.indexOf('=');
            ciphertext = [
                data.slice(0, splitIndex),
                data.slice(splitIndex + 1)
            ];
        }
        else {
            ciphertext = data.split('.')
        }
        let message = ciphertext[0]
        let iv = CryptoJS.enc.Utf8.parse(ciphertext[1])
        const bytes = CryptoJS.AES.decrypt(message, secretKey, { iv: iv })
        plaintext = bytes.toString(CryptoJS.enc.Utf8)
        return plaintext
    }

    // get random 16-bit iv for encryption/decryption
    generateRandomIv(length = 16) {
        return this.generateRandomString(length)
    }

    /**
     * generate n-length random alpha numeric str
     * @param {Number} length 
     * @returns 
     */
    generateRandomString(length) {
        let result = ''
        const charactersLength = characters.length
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
    }

    // call multiple req at once
    async ListAllReqData(ArrofReq, successCallback, mainDb = {}, pagename = undefined) {
        const loginInfo = this.getCookiesData("loginInfo")
        
        let promiseChainArr = []
        ArrofReq.map((data) => {
            let reqheader = {
                token: loginInfo.token,
                issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
                unqkey: loginInfo.unqkey,
                uid: loginInfo.uid,
                platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
                useraction: data.useraction,
                // temperory condition 
                pagename: pagename ? pagename : this.getpagename(),
                subdomainname: loginInfo.subdomainname,
                username: loginInfo.firstname + ' ' + loginInfo.lastname,
                companyid: loginInfo.companyid,
                branchid: loginInfo.branchid,
                userroleid: loginInfo.userroleid,
                masterlisting: data.masterlisting ? true : false,
                ...mainDb,
                version: Config.version,
                usertypeid: loginInfo.usertypeid,
                moduletype: 'web'
                // for public ip
                // publicip : loginInfo.clientpublicip
            }

            let reqbody = data.reqBody

            // hemang jani 30/01/23
            // encrypt req header
            if (Config.encryptdata) {
                reqbody = { reqbody: this.encryptData(reqbody) }
            }
            else {
                reqbody = reqbody
            }

            // hemang jani 30/01/23
            // encrypt req header
            if (Config.encryptdata) {
                reqheader = { reqheader: this.encryptData(reqheader) }
            }
            else {
                reqheader = { ...reqheader }
            }

            promiseChainArr.push(
                axios.post(
                    data.url, reqbody,
                    // hemang jani 30/01/23
                    {
                        headers: {
                            ...reqheader
                        }
                    }
                )
            )
        })

        await Promise.allSettled(promiseChainArr).then(
            (results) => {
                results.map((response, index) => {
                    try {
                        if (response.reason.response.data.updateversion) {
                            IISMethods.HardRefresh()
                        }
                    }
                    catch { }
                    resBody = response.value.data
                    resHeaders = response.value.headers

                    if (response.status == 'fulfilled') {
                        if (Config.encryptdata) {
                            if (ArrofReq[index].masterlisting) {
                                // for master data listing
                                successCallback(JSON.parse(JSON.parse(this.decryptData(response.value.data))), ArrofReq[index].masterdatakey, ArrofReq[index].fieldobj)
                            }
                            else {
                                successCallback(JSON.parse(JSON.parse(this.decryptData(response.value.data))), ArrofReq[index], ArrofReq[index])
                            }
                        }
                        else {
                            if (ArrofReq[index].masterlisting) {
                                // for master data listing
                                successCallback(response.value.data, ArrofReq[index].masterdatakey, ArrofReq[index].fieldobj)
                            }
                            else {
                                successCallback(response.value.data, ArrofReq[index])
                            }
                        }
                    }
                    this.sessionExpiry()
                    this.tokenExpiry()
                })
            }
        )
    }
    // call multiple req at once

    // Socket CRUD operation functions starts | Om Z  🌝🌝🌝 ~ 2024-10-02 ~ 03:50 PM
    async socketListData(urlPara, reqBodyPara, useraction, masterlisting, mainDb = {}, pagename = undefined, showMessageOnError = false, cancelToken = null) {

            const loginInfo = this.getCookiesData("loginInfo")
    console.log('loginInfo', loginInfo);
    
            let reqheader = {
                token: loginInfo.token,
                issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
                unqkey: loginInfo.unqkey,
                uid: loginInfo.uid,
                platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
                useraction: useraction,
                // temperory condition 
                pagename: pagename ? pagename : this.getpagename(),
                subdomainname: loginInfo.subdomainname,
                username: loginInfo.firstname + ' ' + loginInfo.lastname,
                companyid: loginInfo.companyid,
                branchid: loginInfo.branchid,
                userroleid: loginInfo.userroleid,
                masterlisting: masterlisting ? true : false,
                ...mainDb,
                version: Config.version,
                usertypeid: loginInfo.usertypeid,
                moduletype: 'web'
                // for public ip
                // publicip : loginInfo.clientpublicip
            }
    
            // hemang jani 30/01/23
            // encrypt req header
            if (Config.encryptdata) {
                reqHeaders = { reqheader: this.encryptData(reqheader) }
            }
            else {
                reqHeaders = { ...reqheader }
            }
    
            // hemang
            // to check double encryption 
            let index = -1
            try {
                index = reqBody?.indexOf('.')
            } catch { index = -1 }
            // hemang
    
            if (Config.encryptdata && index == -1) {
                if (!reqBody.reqbody) {
                    reqBody = { reqbody: this.encryptData(reqBody) }
                }
            }
    
            // if(url == undefined){
            //     debugger
            // }
            let showCommonNotify = true
            if (reqBodyPara && urlPara) {
                await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], urlPara, reqBodyPara, reqHeaders, listDataSuccessCallback.bind(this), listDataErrorCallback.bind(this), cancelToken)
            }  else {
                await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, listDataSuccessCallback.bind(this), listDataErrorCallback.bind(this), cancelToken)
            }
    
            function listDataSuccessCallback(res) {
                if (Config.encryptdata) {
                    try {
    
                        resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))
                    }
                    catch (e) {
                        resBody = res.data
                    }
    
                    // decrypt res header
                    // hemang jani 30/01/23
                    if (res.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                    }
                    else {
                        resHeaders = res.headers
                    }
                }
                else {
                    resBody = res.data
                    // hemang jani 30/01/23
                    resHeaders = res.headers
                }
            }
    
            function listDataErrorCallback(error,errorWithRes) {
                //if request failed and res than go in this condition
                if(error?.response){
                    if (Config.encryptdata) {
                        // decrypt res body
                        try {
    
                            resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))
    
                        }
                        catch (e) {
                            resBody = error.response.data
                        }
    
                        if (resBody.updateversion) {
                            IISMethods.HardRefresh()
                        }
    
                        // decrypt res header
                        // hemang jani 30/01/23
                        if (error.response.headers.reqheader) {
                            resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                        }
                        else {
                            resHeaders = error.response.headers
                        }
                    }
                    else {
                        // resBody = !error.message ? error.response.data : {} 
                        resBody = error.response.data
                        if (resBody.updateversion) {
                            IISMethods.HardRefresh()
                        }
                        // hemang jani 30/01/23
                        // resHeaders = !error.message ? error.response.headers : {}
                        resHeaders = error.response.headers
                    }
                }
                //if request failed and without res in any case than go in this condition
                else if(!errorWithRes){
                    resBody = {}
                    showCommonNotify = false
                    return
                }
    
                // add error for version conflict
            }
    
            this.sessionExpiry()
            this.tokenExpiry()
            if (showMessageOnError && resBody.status !== 200) {
                if(showCommonNotify){
                    this.notify(resBody.message)
                }
            }
    
            return resBody;
    }

    async socketAddData(urlPara, reqBodyPara, useraction, showResponseMessage = true, pagename = undefined, showMessageOnError = false) {
        const loginInfo = this.getCookiesData("loginInfo")
        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }

        let showCommonNotify = true

        if (reqBodyPara && urlPara) {
            await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], urlPara, reqBodyPara, reqHeaders, addDataSuccessCallback.bind(this), addDataErrorCallback.bind(this))
        } else {
            await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, addDataSuccessCallback.bind(this), addDataErrorCallback.bind(this))
        }

        function addDataSuccessCallback(res) {

            if (Config.encryptdata) {
                try {

                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    // 
                    resBody = res.data
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function addDataErrorCallback(error,errorWithRes) {
            //if request failed and res than go in this condition
            if(error?.response){
                if (Config.encryptdata) {
                    try {
                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))
                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }

                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }
                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if(!errorWithRes){
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showResponseMessage || (showMessageOnError && resBody.status !== 200)) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
        return resBody
    }

    async socketUpdateData(urlPara, reqBodyPara, useraction, showResponseMessage = true, pagename = undefined, showMessageOnError = false) {
        const loginInfo = this.getCookiesData("loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }
        let showCommonNotify = true
         
        if(urlPara && reqBodyPara) {
            await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], urlPara, reqBodyPara, reqHeaders, upadateDataSuccessCallback.bind(this), upadateDataErrorCallback.bind(this))
        } else {
            await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, upadateDataSuccessCallback.bind(this), upadateDataErrorCallback.bind(this))
        }

        function upadateDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try {
                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    resBody = res.data
                }


                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                // hemang jani 30/01/23
                resHeaders = res.headers
            }
        }

        function upadateDataErrorCallback(error,errorWithRes) {
            if(error?.response){
                if (Config.encryptdata) {
                    try {
                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))

                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }

                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if(!errorWithRes){
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showResponseMessage || (showMessageOnError && resBody.status !== 200)) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
        return resBody
    }

    async socketDeleteData(urlPara, reqBodyPara, useraction, showResponseMessage = true, pagename = undefined, showMessageOnError = false) {
        const loginInfo = this.getCookiesData("loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }


        let showCommonNotify = true

        if(urlPara && reqBodyPara) {
            await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_DEL'], urlPara, reqBodyPara, reqHeaders, deleteDataSuccessCallback.bind(this), deleteDataErrorCallback.bind(this))
        } else {
            await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_DEL'], url, reqBody, reqHeaders, deleteDataSuccessCallback.bind(this), deleteDataErrorCallback.bind(this))
        }

        function deleteDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try {
                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    resBody = res.data
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                // hemang jani 30/01/23
                resHeaders = res.headers
            }
        }

        function deleteDataErrorCallback(error,errorWithRes) {
            //if request failed and res than go in this condition
            if (error?.response) {
                if (Config.encryptdata) {
                    try {
                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))

                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }

                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }
                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if (!errorWithRes) {
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showResponseMessage || (showMessageOnError && resBody.status !== 200)) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
        return resBody;
    }

    async socketUpdateFieldOrder(tablename, fieldorder, useraction, showMessageOnError = false, pagename = undefined) {
        const url = Config.weburl + 'fieldorder/add'

        const loginInfo = this.getCookiesData("loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }
        else {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
        }


        let showCommonNotify = true
        await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, updateFieldOrderSuccessCallback.bind(this), updateFieldOrderErrorCallback.bind(this))

        function updateFieldOrderSuccessCallback(res) {

            if (Config.encryptdata) {
                try {

                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    resBody = res.data
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                // hemang jani 30/01/23
                resHeaders = res.headers
            }
        }

        function updateFieldOrderErrorCallback(error,errorWithRes) {
            if(error?.response){
                if (Config.encryptdata) {
                    try {

                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))

                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }

                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }
                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if(!errorWithRes){
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showMessageOnError && resBody.status !== 200) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }

        return resBody;
    }
    // Socket CRUD operation functions ends | Om Z  🌝🌝🌝 ~ 2024-10-02 ~ 03:53 PM

    async listData(useraction, masterlisting, mainDb = {}, pagename = undefined, showMessageOnError = false, cancelToken = null) {

        const loginInfo = this.getCookiesData("loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            // temperory condition 
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            masterlisting: masterlisting ? true : false,
            ...mainDb,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        // hemang
        // to check double encryption 
        let index = -1
        try {
            index = reqBody?.indexOf('.')
        } catch { index = -1 }
        // hemang

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }

        // if(url == undefined){
        //     debugger
        // }
        let showCommonNotify = true
        await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, listDataSuccessCallback.bind(this), listDataErrorCallback.bind(this), cancelToken)

        function listDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try {

                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))
                }
                catch (e) {
                    resBody = res.data
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                // hemang jani 30/01/23
                resHeaders = res.headers
            }
        }

        function listDataErrorCallback(error,errorWithRes) {
            //if request failed and res than go in this condition
            if(error?.response){
                if (Config.encryptdata) {
                    // decrypt res body
                    try {

                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))

                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }

                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }
                }
                else {
                    // resBody = !error.message ? error.response.data : {} 
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    // resHeaders = !error.message ? error.response.headers : {}
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if(!errorWithRes){
                resBody = {}
                showCommonNotify = false
                return
            }

            // add error for version conflict
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showMessageOnError && resBody.status !== 200) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
    }

    async addData(useraction, showResponseMessage = true, pagename = undefined, showMessageOnError = false) {
        const loginInfo = this.getCookiesData("loginInfo")
        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }

        let showCommonNotify = true
        await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, addDataSuccessCallback.bind(this), addDataErrorCallback.bind(this))

        function addDataSuccessCallback(res) {

            if (Config.encryptdata) {
                try {

                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    // 
                    resBody = res.data
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function addDataErrorCallback(error,errorWithRes) {
            //if request failed and res than go in this condition
            if(error?.response){
                if (Config.encryptdata) {
                    try {
                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))
                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }

                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }
                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if(!errorWithRes){
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showResponseMessage || (showMessageOnError && resBody.status !== 200)) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
    }

    async updateData(useraction, showResponseMessage = true, pagename = undefined, showMessageOnError = false) {
        const loginInfo = this.getCookiesData("loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }
        let showCommonNotify = true
        await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, upadateDataSuccessCallback.bind(this), upadateDataErrorCallback.bind(this))

        function upadateDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try {
                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    resBody = res.data
                }


                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                // hemang jani 30/01/23
                resHeaders = res.headers
            }
        }

        function upadateDataErrorCallback(error,errorWithRes) {
            if(error?.response){
                if (Config.encryptdata) {
                    try {
                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))

                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }

                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if(!errorWithRes){
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showResponseMessage || (showMessageOnError && resBody.status !== 200)) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
    }

    async deleteData(useraction, showResponseMessage = true, pagename = undefined, showMessageOnError = false) {
        const loginInfo = this.getCookiesData("loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }


        let showCommonNotify = true
        await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_DEL'], url, reqBody, reqHeaders, deleteDataSuccessCallback.bind(this), deleteDataErrorCallback.bind(this))

        function deleteDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try {
                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    resBody = res.data
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                // hemang jani 30/01/23
                resHeaders = res.headers
            }
        }

        function deleteDataErrorCallback(error,errorWithRes) {
            //if request failed and res than go in this condition
            if (error?.response) {
                if (Config.encryptdata) {
                    try {
                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))

                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }

                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }
                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if (!errorWithRes) {
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showResponseMessage || (showMessageOnError && resBody.status !== 200)) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
    }

    async updateFieldOrder(tablename, fieldorder, useraction, showMessageOnError = false, pagename = undefined) {
        const url = Config.weburl + 'fieldorder/add'

        const loginInfo = this.getCookiesData("loginInfo")

        let reqheader = {
            token: loginInfo.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: pagename ? pagename : this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            version: Config.version,
            usertypeid: loginInfo.usertypeid,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }

        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqHeaders = { reqheader: this.encryptData(reqheader) }
        }
        else {
            reqHeaders = { ...reqheader }
        }

        if (Config.encryptdata) {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
            if (!reqBody.reqbody) {
                reqBody = { reqbody: this.encryptData(reqBody) }
            }
        }
        else {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
        }


        let showCommonNotify = true
        await this.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqHeaders, updateFieldOrderSuccessCallback.bind(this), updateFieldOrderErrorCallback.bind(this))

        function updateFieldOrderSuccessCallback(res) {

            if (Config.encryptdata) {
                try {

                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    resBody = res.data
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                // hemang jani 30/01/23
                resHeaders = res.headers
            }
        }

        function updateFieldOrderErrorCallback(error,errorWithRes) {
            if(error?.response){
                if (Config.encryptdata) {
                    try {

                        resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))

                    }
                    catch (e) {
                        resBody = error.response.data
                    }

                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }

                    // decrypt res header
                    // hemang jani 30/01/23
                    if (error.response.headers.reqheader) {
                        resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                    }
                    else {
                        resHeaders = error.response.headers
                    }
                }
                else {
                    resBody = error.response.data
                    if (resBody.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    // hemang jani 30/01/23
                    resHeaders = error.response.headers
                }
            }
            //if request failed and without res in any case than go in this condition
            else if(!errorWithRes){
                resBody = {}
                showCommonNotify = false
                return
            }
        }

        this.sessionExpiry()
        this.tokenExpiry()
        if (showMessageOnError && resBody.status !== 200) {
            if(showCommonNotify){
                this.notify(resBody.message)
            }
        }
    }

    async downloadDocuments(url) {
        const headers = {
            'Content-Type': 'text/plain'
        };

        await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            headers: headers
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', url.replace(/^.*[\\\/]/, ''));
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(e => console.error(e));
    }

    // HARSH 10-4-2023
    handleCSVDownload(headarr, keyarr, dataarr) {
        const head = ['Sr No.', ...headarr].toString()
        const data = dataarr.map((item, index) => {
            let fields = ['index', ...keyarr]
            let obj = {}
            for (let field of fields) {
                if (field === 'index') {
                    obj[field] = index + 1
                } else {
                    obj[field] = item[field]
                }
            }
            return Object.values(obj).toString()
        })
        const csv = [head, ...data].join('\n')
        this.startCSVDownload(csv)
    }
    startCSVDownload(input) {
        const blob = new Blob([input], { type: 'application/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a');
        a.download = 'test-csv.csv';
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove()
    }

    // harsh 28-6-2023
    convertNumbersToWords(num) {
        let a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];

        let b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        if ((num = num.toString()).length > 9) return 'overflow';

        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

        if (!n) return; var str = '';

        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';

        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';

        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';

        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';

        str += (n[5] != 0) ? ((str != '') ? '' : '') + (a[Number(n[5])] || b[n[5][0]] + '' + a[n[5][1]]) : '';

        return str
    }

    /**
    * 
    * @param {Number} number any value
    * @returns true or false
    */
    isInteger(number) {
        // Convert the input to a number
        const parsedNumber = Number(number)

        // Check if the parsedNumber is a valid number
        if (isNaN(parsedNumber)) {
            return false
        }

        // Check if the parsedNumber is an integer (no fractional part)
        return parsedNumber === Math.floor(parsedNumber)
    }

    //use to convert a sentence into uppercase, lowercase & camelcase
    /**
     * 
     * @param {string} sentence any sentence
     * @param {string} caseType 1 - uppercase | 2 - lowercase | 3 - camelcase
     * @returns converted string
     */
    convertStringCase(sentence, caseType = 1) {
        if (typeof sentence !== "string") return ""

        const cases = {
            1: () => sentence.toUpperCase(),
            2: () => sentence.toLowerCase(),
            3: () => sentence.replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        }

        return cases[caseType]()
    }

}

export var IISMethods = new _IISMethods()