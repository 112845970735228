import ServerMode from './ServerENV'

export default class Config {

	constructor() {
		var weburl
		var imageurl
		var endpointurl
		var endpointverson
		var docurl
		var regdomain
		var mailurl
		var protocol
		var docpath
		var cdnurl
		var servermode
		var maindirpath
		var dirpath
		var page404url
		var nodatafound
		var reporturl

		var mssqldefval
		var mysqldefval
		var errmsg

		var sessionname
		var adminutype

		var savesidebar
		var createandaddmore
		var resetsidebar
		var closesidebar
		var applyfiltersidebar

		var updatemyprofile
		var changepassword
		var changeusertype

		var submitbutton
		var generatereport


		var formloadingtext
		var dummyid

		//for Company Type
		var cmpregisterid
		var cmpunregisterid
		var cmpcompositionschemeid


		var stateid
		var defaultbirthdate

		var defaultcmpid

		var adminuid
		var defaultimageurl
		var defaultcatimageurl
		var paytyperupeeimageurl
		var defaultnotiimageurl

		var socketurl
		var socketserver
		var greetingmessages

		var emailtypes

		var noauth
		var inheritauthfromparent
		var apikey
		var bearertoken
		var basictoken
		var digest
		var oauth1
		var oauth2
		var hawkauthentication
		var awssignature
		var ntlmauthentication
		var akamaiedgergrid

		var followupemail
		var followupmessage
		var followupcall

		var encryptdata
		var encryptSocketData
		var encryptStorage // for local storage / session storage
		var percentageid

		var remindertypeweeklyid

		var daysName
		var daysMasterData

		var projectStatusPriorityid
		var getProjectStatusSeverityId
		var bgColorsArray
		var directExpenceAccountGroup
		// date_default_timezone_set("Asia/Kolkata") 

		var clientTagid
		var version

		/********************************** AWS File Upload & Cognito Start ****************************************/
		var s3_file_upload_region
		var cognito_user_pool_region
		var POOL_NAME
		var POOL_PASS
		var USER_POOL_ID
		var POOL_CLIENT_ID
		var IDENTITY_POOL_ID
		var S3_BUCKET_NAME
		/********************************** AWS File Upload & Cognito End ****************************************/

		/********************************** UserRoleMaster Starts****************************************/
		var customeruserroleid
		var partneruserroleid
		var supersdminuserroleid
		/********************************** UserRoleMaster Ends****************************************/
		/********************************** UserMaster Starts****************************************/
		var usertypes
		/********************************** UserMaster Ends****************************************/


		// date_default_timezone_set("Asia/Kolkata")        
		this.s3_file_upload_region = 'ap-south-1'
		this.cognito_user_pool_region = "us-east-1"

		this.protocol = 'http://'  // http:// or https://
		this.servermode = ServerMode
		this.docroot = __dirname + '/'


		//  project lavel configuration 
		this.sessionname = 'polycafe_27102021'
		this.adminutype = '622ed36cbe213c85a88da9a0'
		this.page404url = '/views/404'

		this.adminuid = '034FB884-A865-4127-B90B-3D06047A72CC'
		this.encryptdata = false
		this.encryptSocketData = false

		this.yesbutton = 'Yes'
		this.nobutton = 'No'
		this.copysidebar = 'Copy'
		this.savesidebar = 'Create' // button name
		this.createandaddmore = 'Create & Add More'
		this.addsidebar = 'Add'
		this.savesidebarname = 'Create'
		this.adddatabuttonname = 'Create'
		this.createtaskbutton = 'Create Task'
		this.downloadbuttonname = 'Download'
		this.resetsidebar = 'Reset'
		this.closesidebar = 'Close'
		this.cancelsidebar = 'Cancel'
		this.updatesidebar = 'Update' // button name
		this.updatesidebarname = 'Update'
		this.applyfiltersidebar = 'Apply'
		this.cancelfiltersidebar = 'Close'
		this.approveSidebar = 'Approve'
		this.rejectbutton = 'Reject'
		this.pendingbutton = 'Pending'
		this.deleteimage = 'deleteImage' //for deleteing preview document
		// harsh 2-2-2023
		this.applytoallsidebar = 'Apply To All'
		// harsh 23-2-2023
		this.clearsidebar = 'Clear'

		this.updatemyprofile = 'Update Profile'
		this.changepassword = 'Change Password'
		this.changeusertype = 'Change Usertype'

		this.submitbutton = 'Submit'
		this.generatereport = 'Generate Report'
		this.nextbutton = 'Next'
		this.previousbutton = 'Previous'
		this.backbutton = 'Back'

		// for delete modal
		this.canceldelete = 'Close'
		// for delete modal
		this.confirmdelete = 'Confirm'

		this.savebutton = 'Save'
		this.generatebutton = 'Generate'
		this.closebutton = 'Close'
		this.cancelbutton = 'Cancel'
		this.saveorderbutton = 'Save Order'
		this.printbutton = 'Print'
		this.commentbutton = 'Comment'
		this.completeinterviewstage = 'Complete Interview'

		// not a bug button
		this.closeissue = 'Close'
		this.reopenissue = 'ReOpen'

		// form loading text
		this.formloadingtext = 'Loading ....'

		this.goback = "Go Back"
		this.yesterdaybutton = "Yesterday"
		this.todaybutton = "Today"
		this.customebutton = "Custom"
		this.sendotpbutton = "Send Otp"
		this.approvalbutton = "Approval"
		this.signinbutton = "Sign In"
		this.notnowbutton = "Not Now"
		this.signupbutton = "Sign Up"
		this.assignedlicense = "Assigned License"
		this.updatebutton = "Update" //for update button
		this.currentplanbutton = "Current Plan"
		this.downgrade = "Downgrade"
		this.buynowbutton ="Buy Now"
		this.verifybutton = "Verify"
		this.okgotitbutton = "Ok, got it"
		this.unassignbutton ="Un-Assign"
		this.assignbutton ="Assign"
		this.deletebutton = "Delete"
		this.renewnowbutton = "Renew Now"
		this.createstagebutton = 'Create Stage'

		this.dummyid = '625d5ed5220ccd5a20904f79'

		this.defaultimageupload = 'https://bizcompass.s3.ap-south-1.amazonaws.com/defaultImageUpload.svg%2Bxml'
		this.defaultCanidateimgupload = 'https://bizcompass.s3.ap-south-1.amazonaws.com/IIS/defaultCandidateimgupload.png'

		//company Type configuration
		this.cmpregisterid = 'E80F52C1-F01C-4301-9438-62CE1E7DD58A'
		this.cmpunregisterid = '0920A058-CFF3-4000-B018-7F168E87054D'
		this.cmpcompositionschemeid = '4A23364E-8A23-4852-9ACF-D14F1294961E'

		this.stateid = '65754311-A0BB-4A7F-9043-05670385BF13'

		this.defaultcmpid = 'B3E18DB5-2A63-4C02-B763-0D3D4A9057CA'

		// For API Authorizarion - Method Pending
		this.noauth = '6262536b1a6be3afecd81d19'
		this.inheritauthfromparent = '6262532e1a6be3afecd81d18'
		this.apikey = '626253891a6be3afecd81d1a'
		this.bearertoken = '6262539b1a6be3afecd81d1b'
		this.basictoken = '626253a41a6be3afecd81d1c'
		this.digest = '626253b61a6be3afecd81d1d'
		this.oauth1 = '626253c11a6be3afecd81d1e'
		this.oauth2 = '626253cb1a6be3afecd81d1f'
		this.hawkauthentication = '626253d31a6be3afecd81d20'
		this.awssignature = '626253db1a6be3afecd81d21'
		this.ntlmauthentication = '626253e81a6be3afecd81d22'
		this.akamaiedgergrid = '626253f91a6be3afecd81d23'

		// project
		this.unassignedcolumnid = "63bfce31ddbfb755cf714ec0"

		// secret key
		this.secretkey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'

		// followupmode types
		this.followupemail = "637331709e5dd7ef7f96736b"
		this.followupmessage = "637336009e5dd7ef7f96736d"
		this.followupcall = "63733a439e5dd7ef7f96736e"

		/********************************** UserRoleMaster Starts****************************************/

		this.customeruserroleid = '63b3d1b45e8f91da7e798967'
		this.partneruserroleid = '63b3d2155e8f91da7e7989a6'
		this.supersdminuserroleid = '622ed36cbe213c85a88da9a0'

		/********************************** UserRoleMaster Ends****************************************/
		/********************************** UserMaster Starts****************************************/

		this.usertypes = {
			'partner': '63dcd2f43811fd853fac3f39',
			'adminuser': '63dcd2f43811fd853fac3f40',
			'customer': '63dcd2f43811fd853fac3f41'
		}

		this.valuetypeMasterDataArray = [{ label: 'Rupee(₹)', value: 1 }, { label: 'Percentage(%)', value: 2 }]


		/********************************** UserMaster Ends****************************************/

		//os Platform array
		this.osPlatformArr = [
			{ 'label': 'Android', 'value': 2 },
			{ 'label': 'iOS', 'value': 3 },
			{ 'label': 'Windows', 'value': 4 },
			{ 'label': 'MacOs', 'value': 5 },
		]

		this.leaveStatusDropdown = [
			{
				label: "Pending",
				value: 1,
			},
			{
				label: "Approved",
				value: 2,
			},
			{
				label: "Rejected",
				value: 3,
			},
			{
				label: "Cancelled",
				value: 4,
			}
		]

		this.daysName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
		this.daysMasterData = [
			{
				label: "Sun",
				value: "Sun"
			},
			{
				label: "Mon",
				value: "Mon"
			},
			{
				label: "Tue",
				value: "Tue"
			},
			{
				label: "Wed",
				value: "Wed"
			},
			{
				label: "Thu",
				value: "Thu"
			},
			{
				label: "Fri",
				value: "Fri"
			},
			{
				label: "Sat",
				value: "Sat"
			},

		]
		// grid list scroll condition
		this.ScrollLimit = 100 // in px

		this.maxLength = 200

		this.bgColorsArray = [
			'#d84841', '#e0ab38', '#b74312', '#d6c335', '#f9f068', '#1fddb7', '#4678f4', '#724db7', '#bf2f4e', '#e8bb68', '#eae467', '#82d849', '#2ce7ea', '#3f118e', '#e04e53', '#ba5f05', '#d5d802', '#66f47c', '#3567a5', '#6f23e0',
			'#d6c335', '#0e9e7c', '#281093', '#b939f9', '#dd635f', '#d37545', '#edd30e', '#54d132', '#1164f4', '#822de2', '#ff21dd', '#cc205c', '#f9830c', '#dbcf29', '#cef204', '#0ce1f4',
			'#4e1b96', '#f246a4', '#f22c29', '#ffca3a', '#f9f940', '#33b52f', '#58c7ef', '#5b15f2', '#cb18e2', '#ff5985', '#e5881d', '#eabf25', '#8dff6d', '#4784aa', '#990ffc', '#ef287e', '#d82d6f', '#db8b29', '#d1ab14', '#37efac',
			'#6b5cd1', '#5a07ea', '#f95ef4', '#8e0511', '#dbad4a', '#d8c13c', '#cfed63', '#1a6684', '#774cdb', '#db3dbb', '#d8423a', '#b75a24', '#e5d740', '#3cfc76', '#2b3dc4', '#5e1687', '#e411f7', '#8c1108', '#ba8a09', '#eae246',
			'#44edd1', '#6839dd', '#4b167f', '#b109f9', '#cc4f5c', '#ed9661', '#f9df18', '#dbf26a', '#0bb0e2', '#8025a8', '#fc71b4', '#ba593b', '#efb734', '#e8d122', '#74bf33', '#704ee0', '#9a4ff7', '#e035b0', '#b70120', '#ba962a',
			'#e0bc47', '#646d01', '#36a5aa', '#9a3fdb', '#ea46a8', '#ad312b', '#d84506', '#fcdf6a', '#dce21d', '#3484ba', '#8e49bc', '#dd35c7', '#e8092e', '#f4a35d', '#efc332', '#c9db62', '#09476b', '#772ef4', '#ed0082', '#e25738',
			'#e0ab38', '#e5d349', '#bcea67', '#5ba0d8', '#814bb7', '#ef58bd', '#a01628', '#e5bb30', '#f4d907', '#01c622', '#5441fc', '#734eba', '#f21897', '#dd6e5f', '#f4ad42', '#e2cc1f', '#c1d60a', '#4351a0', '#8444dd', '#ef1c9e',
			'#ed4b66', '#ba6e27', '#e2db04', '#a4bc38', '#359db2', '#a464fc', '#e01fd3', '#bc1f03', '#eda163', '#dddd4b', '#32bc75', '#36b6c1', '#8d5bd3', '#8c38af', '#fc816f', '#e2671b', '#d1cd0c', '#28ed56', '#3f8fba', '#790ad3',
			'#db04f7', '#e2444c', '#bf670f', '#cbce1e', '#bbf72e', '#7167e5', '#6729ad', '#db36bf', '#c1460d', '#b24d00', '#efdc32', '#acb731', '#0689a0', '#bd63ed', '#e819aa', '#ed6b34', '#ea6523', '#e8d740', '#91c10b', '#080060',
			'#340b8c', '#f94dc0', '#dd3061', '#e2bb56', '#efec43', '#8ae21d', '#4649dd', '#8941ad', '#e560a9', '#c46e4c', '#c97726', '#fcf014', '#18db7c', '#126b8c', '#8320fc', '#770ba5', '#d10e4c', '#d88424', '#fcec5a', '#57f23c',
			'#051782', '#8a38ed', '#ff11c7', '#f74716', '#efa834', '#eadf3f', '#7df740', '#245599', '#a25ac9', '#f544ff', '#c96e52', '#efb062', '#eff446', '#27bc01', '#5496bf', '#502591', '#b900ed', '#ad170a', '#ce8b33', '#ddc04b',
			'#37fcb7', '#061482', '#8f48d6', '#cf4bdd', '#ef6c47', '#ce9825', '#f9e54d', '#2be5bd', '#314796', '#321382', '#e25ad9', '#fc6a8c', '#f7b25d', '#f9e052', '#5ae8d5', '#2e41ba', '#9e57e0', '#c838d8', '#c63b6a', '#c1711b',
			'#d1a919', '#24af32', '#4b82c1', '#9841db', '#e80ba2', '#a01946', '#d69711', '#ddcb27', '#8bdb30', '#1094a5', '#7629c4', '#9d15d3', '#c10d4f', '#e8c566', '#e5d710', '#8cf207', '#5c89f9', '#6c48ba', '#db559c', '#e5505a',
			'#efa34c', '#f9ec36', '#36db44', '#3b63ce', '#aa37f2', '#ff07ee', '#fc6358', '#ed7f50', '#ffef44', '#77bc3a', '#1c86dd', '#8d35d6', '#ef3b98', '#c93e4c', '#eab964', '#f9ff59', '#e5f970', '#148aa8', '#5612e8', '#c12cea',
		]

		if (this.servermode == "dev") // Development
		{
			this.protocol = 'http://'
			this.maindirpath = '/'
			this.dirpath = this.maindirpath + ''  /* Directory Name also look in index.php script tag*/
			if (localStorage.getItem('ip')) {
				this.host = "192.168.2." + localStorage.getItem('ip') + ':8089' + this.dirpath
			}
			else {
				// this.host = "192.168.2.147:8089" + this.dirpath //Tilak's ip
				// this.host = "192.168.2.146:8089" + this.dirpath //darsh's ip
				this.host = "192.168.2.78:8089" + this.dirpath //Ruchir's ip
				// this.host = "192.168.2.74:8089" + this.dirpath //Priyanshi's ip
				// this.host = "192.168.2.15:8089" + this.dirpath //hemang's ip
				// this.host = "192.168.2.12:8089" + this.dirpath //shubham's ip
				// this.host = "192.168.2.83:8089" + this.dirpath //aman's ip
				// this.host = "192.168.2.16:8089" + this.dirpath //Ami's ip
			}

			this.endpointverson = 'v1/'
			this.weburl = this.protocol + this.host + this.endpointverson
			this.docpath = this.docroot
			this.cdnurl = this.protocol + this.host
			this.regdomain = '.bizcompass.app'
			this.mailurl = "192.168.2.2/PolyCafe_Test/"
			this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
			this.imageurl = this.protocol + this.maindirpath + 'assets/'
			this.page404url = this.protocol + this.host + this.page404url
			this.nodatafound = this.protocol + this.host + 'views/nodatafound.php'
			this.reporturl = this.protocol + this.host + 'report/'

			this.defaultimageurl = this.imageurl + 'images/defaultitem.png'
			this.defaultcatimageurl = this.imageurl + 'images/defaultcategory.png'
			this.paytyperupeeimageurl = this.imageurl + 'images/rupee.png'
			this.defaultnotiimageurl = this.imageurl + 'images/defaultnotification.png'
			this.socketurl = 'http://192.168.1.26:3001/socket.io/socket.io.js'
			this.socketserver = 'http://192.168.2.78:8083'
			this.encryptdata = false
			this.encryptSocketData = false
			this.encryptStorage = false
			this.version = 5
			this.POOL_NAME = 'uat_file_upload'
			this.POOL_PASS = 'Asd@123!@#'
			this.USER_POOL_ID = "us-east-1_SeuSlO9eP"
			this.POOL_CLIENT_ID = '3pulqd4j3vuund57cnpqi6qjkt'
			this.IDENTITY_POOL_ID = 'us-east-1:f8afd869-aa67-4b6c-917a-a82254e28fdb'
			this.S3_BUCKET_NAME = 'uat-bizcompass'

		}
		else if (this.servermode == "prod") // live 
		{
			this.protocol = 'https://'
			this.maindirpath = '/'
			this.dirpath = this.maindirpath + '' /* Directory Name */
			this.host = "cp.bizcompass.io" + this.dirpath
			this.endpointverson = 'v1/'
			this.weburl = this.protocol + this.host + this.endpointverson
			this.docpath = this.docroot
			this.cdnurl = this.protocol + this.host
			this.regdomain = '.bizcompass.com'
			this.mailurl = " "
			this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
			this.imageurl = this.protocol + "" + this.maindirpath + 'assets/'
			this.page404url = this.protocol + this.host + this.page404url
			this.nodatafound = this.protocol + this.host + 'views/nodatafound.php'
			this.reporturl = this.protocol + this.host + 'report/'

			this.defaultimageurl = this.imageurl + 'images/defaultitem.jpg'
			this.defaultcatimageurl = this.imageurl + 'images/defaultcategory.png'
			this.paytyperupeeimageurl = this.imageurl + 'images/rupee.png'
			this.defaultnotiimageurl = this.imageurl + 'images/defaultnotification.png'
			this.socketurl = 'http://192.168.1.26:3001/socket.io/socket.io.js'
			this.socketserver = 'socket.bizcompass.io'
			this.encryptdata = false
			this.encryptSocketData = false
			this.version = 5
			this.encryptStorage = true
			this.POOL_NAME = 'bizcompass_react'
			this.POOL_PASS = 'Asd@123!@#'
			this.USER_POOL_ID = "us-east-1_9wd50z9ge"
			this.POOL_CLIENT_ID = '2hptp1b8v23u956u07t7m8i167'
			this.IDENTITY_POOL_ID = 'us-east-1:137d1c33-8a04-41ac-b128-12625c37f357'
			this.S3_BUCKET_NAME = 'bizcompass'

		}
		else if (this.servermode == "uat") // test 
		{
			this.protocol = 'https://'
			this.maindirpath = '/'
			this.dirpath = this.maindirpath + '' /* Directory Name */
			// this.host = "192.168.2.27:3030" + this.dirpath
			this.host = "cpapi.bizcompassapp.com" + this.dirpath
			this.endpointverson = 'v1/'
			this.weburl = this.protocol + this.host + this.endpointverson
			this.docpath = this.docroot
			this.cdnurl = this.protocol + this.host
			this.regdomain = '.bizcompass.com'
			this.mailurl = " "
			this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
			this.imageurl = this.protocol + "" + this.maindirpath + 'assets/'
			this.page404url = this.protocol + this.host + this.page404url
			this.nodatafound = this.protocol + this.host + 'views/nodatafound.php'
			this.reporturl = this.protocol + this.host + 'report/'

			this.defaultimageurl = this.imageurl + 'images/defaultitem.jpg'
			this.defaultcatimageurl = this.imageurl + 'images/defaultcategory.png'
			this.paytyperupeeimageurl = this.imageurl + 'images/rupee.png'
			this.defaultnotiimageurl = this.imageurl + 'images/defaultnotification.png'
			this.socketurl = 'http://192.168.1.26:3001/socket.io/socket.io.js'
			// this.socketserver = 'http://office.instanceit.com:3030'
			this.socketserver = 'http://socket.bizcompassapp.com:3000'
			this.encryptdata = false
			this.encryptSocketData = false
			this.version = 5
			this.encryptStorage = true
			this.POOL_NAME = 'uat_file_upload'
			this.POOL_PASS = 'Asd@123!@#'
			this.USER_POOL_ID = "us-east-1_SeuSlO9eP"
			this.POOL_CLIENT_ID = '3pulqd4j3vuund57cnpqi6qjkt'
			this.IDENTITY_POOL_ID = 'us-east-1:f8afd869-aa67-4b6c-917a-a82254e28fdb'
			this.S3_BUCKET_NAME = 'uat-bizcompass'

		}
		// else if(this.servermode=="uat") // test 
		// { 
		// 	this.maindirpath=''
		// 	this.dirpath=this.maindirpath + '' /* Directory Name */
		//     this.host="" + this.dirpath
		//     this.weburl = this.protocol + this.host + '/'
		// 	this.docpath = this.docroot
		//     this.cdnurl = 'https://d13gezj0xhzun1.cloudfront.net/'
		//     this.regdomain = 'totalityuat.com'
		//     this.mailurl = "totalityuat.com/"
		// 	this.endpointverson = 'v1.0/'
		// 	this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
		// 	this.imageurl = this.protocol + "" + this.maindirpath + 'assets/'
		// 	this.page404url=this.protocol + this.host + this.page404url
		// 	this.nodatafound=this.protocol + this.host + 'views/nodatafound.php'
		// 	this.reporturl=this.protocol + this.host + 'report/'

		// 	this.defaultimageurl=this.imageurl + 'images/defaultitem.png'
		// 	this.defaultcatimageurl=this.imageurl + 'images/defaultcategory.png'
		// 	this.paytyperupeeimageurl=this.imageurl + 'images/rupee.png'
		// 	this.defaultnotiimageurl=this.imageurl + 'images/defaultnotification.png'
		// }

		//for error message

		this.errmsg = {
			'networkerror' : 'Network error. Please check your connection.',
			'noresreceived' : 'No response received. Please check your network connection.',
			'reqsetiperror' : 'Request setup error. Please check your configuration.',
			'reqtimeout' : 'The request timed out. Please try again later.',
			'insert': 'Data inserted successfully.',
			'update': 'Data updated successfully.',
			'delete': 'Data deleted successfully.',
			'reqired': 'Please fill in all required fields.',
			'inuse': 'Data is already in use.',
			'isexist': 'Data already exist.',
			'dberror': 'Something went wrong, Error Code : ',
			'userright': "Sorry, You don't have enough permissions to perform this action",
			'size': "Sorry, You don't have enough permissions to perform this action",
			'type': "Sorry, You don't have enough permissions to perform this action.",
			'success': "Data found",
			'error': "Error",
			'nodatafound': "No data found",
			'uservalidate': "User Validate.",
			'deactivate': "Your account is suspended, please contact administrator to activate account.",
			'invalidrequest': "Invalid request.",
			'sessiontimeout': "Session timeout",
			'dataduplicate': "Data Duplicated Succesfully",
			'tokenvalidate': "Token validated",
			'invalidtoken': "Invalid token.",
			'usernotfound': "User not found",
			'invalidusername': "Invalid Username or Password.",
			'invalidpassword': "Invalid Username or Password..",
			'verifyemail': "Please verify your email addess",
			'filetype': "Invalid file extension",
			'loginright': "Sorry, You don't have enough permissions to login. Please contact admin",
			'somethingwrong': 'Sorry, something went wrong.',
			'loginsuccess': "Login Successfully",
			'logoutsuccess': "Logout Successfully",
			'appupdate': "We have released new version of Poly Cafe App. Download the update and install to continue use this App.",
			'profile-update': "Profile updated successfully",
			'passchanged': "Password changed successfully",
			'correctpass': "Please enter correct old password",
			'csvdownload': "CSV downloaded successfully",
			'invalidbarcode': "Invalid barcode",
			'itemalreadyassign': "Item already assigned",
			'itemassign': "Item assigned successfully",
			'itemreassign': "Item reassigned successfully",
			'newnodeadd': "New Nodes added successfully",
			'nodedelnot': "Can't delete super admin node",
			'nodedel': "Node deleted successfully",
			'noitemfound': "No item found",
			'cancelorditem': "Order item cancelled successfully",
			'reqorderseries': "Order series data is required",
			'nonotifound': "No notification found",
			'noorderfound': "No order found",
			'itemready': "Item has been ready successfully",
			'itemserved': "Item has been served successfully",
			'paycollectsuccess': "Payment collected successfully",
			'orderaccept': "Order accepted successfully",
			'ordpreparetime': "Order preparation time updated successfully",
			// harsh 10-2-2023
			'comparestartdate': "From date should be smallar than To date",
			'compareenddate': "To date should be greater than From date",
			'comparestartenddate': "End date should be greater than start date",
			// harsh 16-3-2023
			'invalidfile': "Invalid File Type",
			'timeestimation': "Estimation time should be less than available task time",
			'zeroestimation': 'Please enter valid estimation',
			'dropotherstask': "Can't Drop Other's Task",
			'droptimeron': "Can't Drop Timer is On",
			'timernotstarted': "Timer not started yet",
			'subtaskshouldbecomplete': "Subtask should be completed",
			'duplicatedata': 'Duplicate Data',
			'fillrequireddata':'Fill all required data',
			'fillrequiredfields':'Fill all required fields',
			'tomonthvalidation' : 'To Month must be greater than From Month',
			'slabalreadyadded' : 'Slab is already added for this month',
			'charvalidation' : 'Maximum 5 Character Allowed',
			'minspendvalidation' : 'Minimumspend must be less than Maximumspend',
			'maxspendvalidation' : 'Maximumspend must be geater than Minimumspend',
			'userlimitvalidationpercoupon' : 'Usage Limit Per Coupon must be geater than Usage Limit Per User',
			'userlimitvalidationperuser' : 'Usage Limit Per User must be less than Usage Limit Per Coupon',
			'selectuserroleperson' : 'Select User role Or Person',
			'userroleselectionvalidation' : "Same Userrole's Commision Slab can't be selected",
			'tenuremonthalreadyadded' : 'Tenure month is already added',
			'invalidemail' : 'Invalid Email',
			'passwordnotmatch' : 'Password Dose Not Match',
			'valuevalidationperwallet' : 'value is greater than wallet amount',
			'valuevalidationperpayable' : 'value is greater than payable amount',
			'referralcodevalidation': 'Enter Valid Referral Code',
			'codecopied' : 'Code Copied',
			'urlcopied' : 'URL Copied',
			'couponcodevalidation' : 'Enter valid Coupon code',
			'cannotdrop' : "Can't Drop",
			'completeflow': 'Complete Flow',
			'emptyflownotallow': 'Empty Flow Not Allowded',
			'sourcesvalidation': 'Can Not have more than one sources',
			'circularflowerr': 'Can Not have Circular Flow',
			'atleastonenodeswitchon': 'Atleast One node should have Complete switch on',
			'nodealreadyadded': 'Node Already Added',
			'cannotconnecttounassigned': "Can't Connect To Unassigned",
			'selfconnectnotallow': 'Self Connect Not Allowded',
			'nodesconnected': 'Nodes are already connected',
			'cannotconnect': 'Can not Connect',
			'cannotremoveunassignednode': "Can't Remove Unassigned Node",
			'ticketspreset': 'Some tickets are present',
			"stagecannotremove": "this stage cannot be removed.",
			'mediastorage': 'The media storage capacity has been exceeded, leaving no available space.',
			'docuploaderr': "Something Went Wrong ,Please Try Again",
			'flownotcompleted': 'Flow is not Completed',
			'atleastonenodetimerswitchon': 'Atleast One node should have Timer Tracking switch on',
		}

		this.greetingmessages = [
			'Welcome, ', 'Howdy, ', 'Hello, '
		]

		this.regex = {
			// 'phone': /^(?:\+\d{1,3}\s?)?(?:\(\d{1,}\)\s?)*\d{7,14}$/,
			// 'phone': /^(?:\+\d{1,3}\s?)?(?:\(\d{1,}\)\s?)*\d{7,14}$/,
			'phone': /^(\+\d{1,3}[-\s]?)?(?:\(\d{1,4}\)[- \s]?)?(?:\d{1,4}[-\s]?)?(?:\d{1,4}[-\s]?)?(?:\d{1,4}[-\s]?)?(?:\d{1,4}[-\s]?)?$|^.{7,15}$/,
			// 'phone': '^(?:[0-9] ?){9,14}[0-9]$',
			// 'email': '^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$',
			// 'email': /^\w+([\.-]?\w+)*@\w+(\.\w{2,3})+$/,
			// 'email': /^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w{2,4}$/,
			'email': /^(?!.*[A-Z])\w+([-.]\w+)*@\w+([-.]\w+)*\.\w{2,4}$/,
			'signinmail': /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // for sign in time write email in both capital and small letter | Nirzar Gabani 💀 ~  2025-03-24 ~ 11:37 AM
			'password': /^(?=[a-zA-Z0-9!@#$%^&*_]{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
			// 'gstno': '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
			'pincode': '^[1-9][0-9]{5}$',
			'aadharno': '^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$',
			'panno': '^[A-Z]{5}[0-9]{4}[A-Z]{1}$',
			'ifsccode': '^[A-Z]{4}0[A-Z0-9]{6}$',
			'tinno': '^[A-Z]{5}[0-9]{4}[A-Z]{1}$', //temp
			// 'tinno' : '^(9\d{2})([ \-]?)([7]\d|8[0-8])([ \-]?)(\d{4})$', //ori
			'name': '^[a-zA-Z\\s]*$',
			'contactno': '^(?:[0-9] ?){6,14}[0-9]$',
			'accountno': '^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$',
			'exportbondno': '^(\d{2})-?([a-zA-Z]{3})-?(\d{6})-?(\d{2})-?(\d{1,4})$/',
			'numberwithdecimal': '[0-9+_.-]$',
			'floatnumber': '^\d*\.?\d*$',
			'subdomain': /^([a-z0-9]){1,25}$/,
			'gstno': '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
		}

		this.htmlcontorls = {

			'switch': 'switch',
			'text': 'text',
			'icon': 'icon',
			'input-text': 'input-text',
			'password': 'password',
			'input-textarea': 'input-textarea',
			'checkbox': 'checkbox',
			'dropdown': 'dropdown',
			'radio': 'radio',
			'multipleselectdropdown': 'multipleselectdropdown',
			'lookup': 'lookup',
			'combobox': 'combobox',
			'number-input': 'number-input',
			'input': 'input',
			'datepicker': 'datepicker',
			'rangepicker': 'rangepicker',
			'daterangepicker': 'daterangepicker',
			'monthrangepicker': 'monthrangepicker',
			'datetimepicker': 'datetimepicker',
			'timerangepicker': 'timerangepicker',
			'timepicker': 'timepicker',
			'monthpicker': 'monthpicker',
			'colorpicker': 'colorpicker',
			'image': 'image',
			'email': 'email',
			'phone': 'phone',
			'html-editor': 'html-editor',
			'text-array': 'text-array',
			'modal-eye': 'modal-eye',
			'modal-text': 'modal-text',
			'file': 'file',
			'table': 'table',
			'table-add-button': 'table-add-button',
			'colorpickerpreview': 'colorpickerpreview',
			'drag-container': 'drag-container',
			'combinefield': 'combinefield',
			'number-input-message': 'number-input-message',
			'tag-input': 'tag-input',
			'tag-picker': 'tag-picker',
			'multiplefilepicker': 'multiplefilepicker',
			'checkpicker': 'checkpicker',
			'personimg': "personimg",
			'icons': 'icons',
			'float-number-input': 'float-number-input',
			'contact-number': 'contact-number', // this is added to handle contact number
			'suneditor': 'suneditor-html',
		}

		this.emailtypes = {
			'1': 'Schedule Interview',
			'2': 'Reschedule Interview',
			'3': 'Canceled Interview',
			'4': 'Schedule Interview Candidate',
			'5': 'Reschedule Interview Candidate',
			'6': 'Cancle Interview Candidate',
			'7': 'Appointment Letter',
			'8': 'Offer Letter',
			'9': 'Agreement',
			'10': 'Policy',
			'11': 'Schedule Time Blocks',
			'12': 'Employee generate',
			'13': 'Trainer Assigned To Trainee',
			'14': 'Candidate Reject',
			'15': 'Company Reject',
			'16': 'Online Test',
			'17': 'Introduction',
		}

		this.modalsizeclasses = {
			'xs': 'xs',
			'sm': 'sm',
			'md': 'md',
			'lg': 'lg',
			'xl': 'xl',
			'full': 'full'
		}

		this.gridclasses = {
			'col': 'col',
			'col-auto': 'col-auto',
			'col-1': 'col-1',
			'col-2': 'col-2',
			'col-3': 'col-3',
			'col-4': 'col-4',
			'col-5': 'col-5',
			'col-6': 'col-6',
			'col-7': 'col-7',
			'col-8': 'col-8',
			'col-9': 'col-9',
			'col-10': 'col-10',
			'col-11': 'col-11',
			'col-12': 'col-12',

			'col-xs': 'col-xs',
			'col-xs-auto': 'col-xs-auto',
			'col-xs-1': 'col-xs-1',
			'col-xs-2': 'col-xs-2',
			'col-xs-3': 'col-xs-3',
			'col-xs-4': 'col-xs-4',
			'col-xs-5': 'col-xs-5',
			'col-xs-6': 'col-xs-6',
			'col-xs-7': 'col-xs-7',
			'col-xs-8': 'col-xs-8',
			'col-xs-9': 'col-xs-9',
			'col-xs-10': 'col-xs-10',
			'col-xs-11': 'col-xs-11',
			'col-xs-12': 'col-xs-12',

			'col-sm': 'col-sm',
			'col-sm-auto': 'col-sm-auto',
			'col-sm-1': 'col-sm-1',
			'col-sm-2': 'col-sm-2',
			'col-sm-3': 'col-sm-3',
			'col-sm-4': 'col-sm-4',
			'col-sm-5': 'col-sm-5',
			'col-sm-6': 'col-sm-6',
			'col-sm-7': 'col-sm-7',
			'col-sm-8': 'col-sm-8',
			'col-sm-9': 'col-sm-9',
			'col-sm-10': 'col-sm-10',
			'col-sm-11': 'col-sm-11',
			'col-sm-12': 'col-sm-12',

			'col-md': 'col-md',
			'col-md-auto': 'col-md-auto',
			'col-md-1': 'col-md-1',
			'col-md-2': 'col-md-2',
			'col-md-3': 'col-md-3',
			'col-md-4': 'col-md-4',
			'col-md-5': 'col-md-5',
			'col-md-6': 'col-md-6',
			'col-md-7': 'col-md-7',
			'col-md-8': 'col-md-8',
			'col-md-9': 'col-md-9',
			'col-md-10': 'col-md-10',
			'col-md-11': 'col-md-11',
			'col-md-12': 'col-md-12',

			'col-lg': 'col-lg',
			'col-lg-auto': 'col-lg-auto',
			'col-lg-1': 'col-lg-1',
			'col-lg-2': 'col-lg-2',
			'col-lg-3': 'col-lg-3',
			'col-lg-4': 'col-lg-4',
			'col-lg-5': 'col-lg-5',
			'col-lg-6': 'col-lg-6',
			'col-lg-7': 'col-lg-7',
			'col-lg-8': 'col-lg-8',
			'col-lg-9': 'col-lg-9',
			'col-lg-10': 'col-lg-10',
			'col-lg-11': 'col-lg-11',
			'col-lg-12': 'col-lg-12',

			'col-xl': 'col-xl',
			'col-xl-auto': 'col-xl-auto',
			'col-xl-1': 'col-xl-1',
			'col-xl-2': 'col-xl-2',
			'col-xl-3': 'col-xl-3',
			'col-xl-4': 'col-xl-4',
			'col-xl-5': 'col-xl-5',
			'col-xl-6': 'col-xl-6',
			'col-xl-7': 'col-xl-7',
			'col-xl-8': 'col-xl-8',
			'col-xl-9': 'col-xl-9',
			'col-xl-10': 'col-xl-10',
			'col-xl-11': 'col-xl-11',
			'col-xl-12': 'col-xl-12',

			'col-exl': 'col-exl',
			'col-exl-auto': 'col-exl-auto',
			'col-exl-1': 'col-exl-1',
			'col-exl-2': 'col-exl-2',
			'col-exl-3': 'col-exl-3',
			'col-exl-4': 'col-exl-4',
			'col-exl-5': 'col-exl-5',
			'col-exl-6': 'col-exl-6',
			'col-exl-7': 'col-exl-7',
			'col-exl-8': 'col-exl-8',
			'col-exl-9': 'col-exl-9',
			'col-exl-10': 'col-exl-10',
			'col-exl-11': 'col-exl-11',
			'col-exl-12': 'col-exl-12',

			'p-0': 'p-0'
		}

		// this.tblSize

		this.aliasname = {
			'homealias': 'home',
			'walletalias': 'wallet',
			'myprofilealias': 'myprofile',
			'userrightsalias': 'userrights',
			'userrightstemplatealias': 'userrightstemplate',
			'myreferralsalias': 'myreferrals',
			'modulemasteralias': 'modulemaster',
			'menumasteralias': 'menumaster',
			'menuassignalias': 'menuassign',
			'menudesignalias': 'menudesign',
			'userrolemasteralias': 'userrolemaster',
			'userrolehierarchyalias': 'userrolehierarchy',
			'packagemasteralias': 'packagemaster',
			'iconmasteralias': 'iconmaster',
			'activepackagesalias': 'activepackages',
			'useralias': 'user',
			'commissionslabmasteralias': 'commissionslabmaster',
			'subuseralias': 'subuser',
			'couponalias': 'coupon',
			'packageiconalias': 'packageicon',
			'packagemodulealias': 'packagemodule',
			'packagepurchasesalias': 'packagepurchases',
			'listusertreealias': 'listusertree',
			'packagemenualias': 'packagemenu',
			'packagemenuassignalias': 'packagemenuassign',
			'packagemenudesignalias': 'packagemenudesign',
			'appreleasealias': 'apprelease',
			'accounttypealias': 'accounttype',
			'algorithmalias': 'algorithm',
			'authorizationtypealias': 'authorizationtype',
			'billingmethodalias': 'billingmethod',
			'bloodgroupalias': 'bloodgroup',
			'bonuspaytypealias': 'bonuspaytype',
			'companytypealias': 'companytype',
			'companyworkdaytypealias': 'companyworkdaytype',
			'defaultaccountalias': 'defaultaccount',
			'documentcopyalias': 'documentcopy',
			'genderalias': 'gender',
			'graduationtypealias': 'graduationtype',
			'gsttypealias': 'gsttype',
			'incentivealias': 'incentive',
			'installmenttypealias': 'installmenttype',
			'issuetypealias': 'issuetype',
			'loptypealias': 'loptype',
			'marritalstatusalias': 'marritalstatus',
			'payrolltypealias': 'payrolltype',
			'positionalias': 'position',
			'recurringfrequencyalias': 'recurringfrequency',
			'referencetypealias': 'referencetype',
			'remindertypealias': 'remindertype',
			'requesttypealias': 'requesttype',
			'salarytypealias': 'salarytype',
			'severitytypealias': 'severitytype',
			'valuetypealias': 'valuetype',
			'weekofftypealias': 'weekofftype',
			'badgesalias': 'badges',
			'documenttypealias': 'documenttype',
			'employmenttypealias': 'employmenttype',
			'exitlettertypealias': 'exitlettertype',
			'followupmodealias': 'followupmode',
			'primarycandidatesourcealias': 'primarycandidatesource',
			'resignreasonalias': 'resignreason',
			'serieselementalias': 'serieselement',
			'seriestypemasteralias': 'seriestypemaster',

			'spacealias': 'space',
			'statustypealias': 'statustype',
			'surveythemealias': 'surveytheme',
			'surveytypealias': 'surveytype',
			'transtypealias': 'transtype',
			'followupstagealias': 'followupstage',
			'followupstagemasteralias': 'followupstagemaster',
			'moduletypemasteralias': 'moduletypemaster',
			'questiontypealias': 'questiontype',
			'unitmasteralias': 'unitmaster',
			'assetstatusalias': 'assetstatus',
			'typealias': 'type',
			'agreementtypealias': 'agreementtype',
			'errorlogsalias': 'errorlogs',
			'dashboarduserrightsalias': 'dashboarduserrights',
			'taxalias': 'tax',
			'manualalias': 'manual',
			'timzonealias': 'timezone',

			'supportcategoryalias': 'supportcategory',
			'supportticketflowalias': 'supportticketflow',
			'supportticketstagealias': 'supportticketstage',
			'supportticketstageflowalias': 'supportticketstageflow',
		}

		this.pagenamelist = {
			'home': 'home',
			'wallet': 'wallet',
			'myprofile': 'myprofile',
			'userrights': 'userrights',
			'userrightstemplate': 'userrightstemplate',
			'myreferrals': 'myreferrals',
			'module': 'module',
			'menu': 'menu',
			'menuassign': 'menuassign',
			'menudesign': 'menudesign',
			'userrole': 'userrole',
			'userrolehierarchy': 'userrolehierarchy',
			'package': 'package',
			'icon': 'icon',
			'activepackages': 'activepackages',
			'user': 'user',
			'commissionslab': 'commissionslab',
			'subuser': 'subuser',
			'coupon': 'coupon',
			'packageicon': 'packageicon',
			'packagemodule': 'packagemodule',
			'packagepurchases': 'packagepurchases',
			'listusertree': 'listusertree',
			'packagemenu': 'packagemenu',
			'packagemenuassign': 'packagemenuassign',
			'packagemenudesign': 'packagemenudesign',
			'apprelease': 'apprelease',
			'accounttype': 'accounttype',
			'algorithm': 'algorithm',
			'authorizationtype': 'authorizationtype',
			'billingmethod': 'billingmethod',
			'bloodgroup': 'bloodgroup',
			'bonuspaytype': 'bonuspaytype',
			'companytype': 'companytype',
			'companyworkdaytype': 'companyworkdaytype',
			'defaultaccount': 'defaultaccount',
			'documentcopy': 'documentcopy',
			'gender': 'gender',
			'graduationtype': 'graduationtype',
			'gsttype': 'gsttype',
			'incentive': 'incentive',
			'installmenttype': 'installmenttype',
			'issuetype': 'issuetype',
			'loptype': 'loptype',
			'maritalstatus': 'maritalstatus',
			'payrolltype': 'payrolltype',
			'position': 'position',
			'recurringfrequency': 'recurringfrequency',
			'referencetype': 'referencetype',
			'remindertype': 'remindertype',
			'requesttype': 'requesttype',
			'salarytype': 'salarytype',
			'severitytype': 'severitytype',
			'valuetype': 'valuetype',
			'weekofftype': 'weekofftype',
			'badges': 'badges',
			'documenttype': 'documenttype',
			'employmenttype': 'employmenttype',
			'exitlettertype': 'exitlettertype',
			'followupmode': 'followupmode',
			'primarycandidatesource': 'primarycandidatesource',
			'resignreason': 'resignreason',
			'serieselement': 'serieselement',
			'seriestypemaster': 'seriestypemaster',

			'space': 'space',
			'statustype': 'statustype',
			'surveytheme': 'surveytheme',
			'surveytype': 'surveytype',
			'transtype': 'transtype',
			'followupstage': 'followupstage',
			'followupstagemaster': 'followupstagemaster',
			'moduletypemaster': 'moduletypemaster',
			'questiontype': 'questiontype',
			'unitmaster': 'unitmaster',
			'assetstatus': 'assetstatus',
			'type': 'type',
			'agreementtype': 'agreementtype',
			'errorlogs': 'errorlogs',
			'dashboardrights': 'dashboardrights',
			'tax': 'tax',
			'manual': 'manual',
			'timezone' : 'timezone',
			'supportcategory': 'supportcategory',
			'supportticketflow': 'supportticketflow',
			'supportticketstage': 'supportticketstage',
			'supportticketstageflow': 'supportticketstageflow'
			
		}

		this.sidebarsizeclasses = {
			'sm-sidebar': 'sm-sidebar',
			'md-sidebar': 'md-sidebar',
			'lg-sidebar': 'lg-sidebar',
			'xl-sidebar': 'xl-sidebar'
		}

		this.marginclasses = {
			'm-auto': 'm-auto',
			'm-0': 'm-0',
			'm-1': 'm-1',
			'm-2': 'm-2',
			'm-3': 'm-3',
			'm-4': 'm-4',
			'm-5': 'm-5',
			'mt-auto': 'mt-auto',
			'mt-0': 'mt-0',
			'mt-1': 'mt-1',
			'mt-2': 'mt-2',
			'mt-3': 'mt-3',
			'mt-4': 'mt-4',
			'mt-5': 'mt-5',
			'mb-auto': 'mb-auto',
			'mb-0': 'mb-0',
			'mb-1': 'mb-1',
			'mb-2': 'mb-2',
			'mb-3': 'mb-3',
			'mb-4': 'mb-4',
			'mb-5': 'mb-5',
			'mr-auto': 'mr-auto',
			'mr-0': 'mr-0',
			'mr-1': 'mr-1',
			'mr-2': 'mr-2',
			'mr-3': 'mr-3',
			'mr-4': 'mr-4',
			'mr-5': 'mr-5',
			'ml-auto': 'ml-auto',
			'ml-0': 'ml-0',
			'ml-1': 'ml-1',
			'ml-2': 'ml-2',
			'ml-3': 'ml-3',
			'ml-4': 'ml-4',
			'ml-5': 'ml-5',

			'm-xs-auto': 'm-xs-auto',
			'm-xs-0': 'm-xs-0',
			'm-xs-1': 'm-xs-1',
			'm-xs-2': 'm-xs-2',
			'm-xs-3': 'm-xs-3',
			'm-xs-4': 'm-xs-4',
			'm-xs-5': 'm-xs-5',
			'mt-xs-auto': 'mt-xs-auto',
			'mt-xs-0': 'mt-xs-0',
			'mt-xs-1': 'mt-xs-1',
			'mt-xs-2': 'mt-xs-2',
			'mt-xs-3': 'mt-xs-3',
			'mt-xs-4': 'mt-xs-4',
			'mt-xs-5': 'mt-xs-5',
			'mb-xs-auto': 'mb-xs-auto',
			'mb-xs-0': 'mb-xs-0',
			'mb-xs-1': 'mb-xs-1',
			'mb-xs-2': 'mb-xs-2',
			'mb-xs-3': 'mb-xs-3',
			'mb-xs-4': 'mb-xs-4',
			'mb-xs-5': 'mb-xs-5',
			'mr-xs-auto': 'mr-xs-auto',
			'mr-xs-0': 'mr-xs-0',
			'mr-xs-1': 'mr-xs-1',
			'mr-xs-2': 'mr-xs-2',
			'mr-xs-3': 'mr-xs-3',
			'mr-xs-4': 'mr-xs-4',
			'mr-xs-5': 'mr-xs-5',
			'ml-xs-auto': 'ml-xs-auto',
			'ml-xs-0': 'ml-xs-0',
			'ml-xs-1': 'ml-xs-1',
			'ml-xs-2': 'ml-xs-2',
			'ml-xs-3': 'ml-xs-3',
			'ml-xs-4': 'ml-xs-4',
			'ml-xs-5': 'ml-xs-5',

			'm-sm-auto': 'm-sm-auto',
			'm-sm-0': 'm-sm-0',
			'm-sm-1': 'm-sm-1',
			'm-sm-2': 'm-sm-2',
			'm-sm-3': 'm-sm-3',
			'm-sm-4': 'm-sm-4',
			'm-sm-5': 'm-sm-5',
			'mt-sm-auto': 'mt-sm-auto',
			'mt-sm-0': 'mt-sm-0',
			'mt-sm-1': 'mt-sm-1',
			'mt-sm-2': 'mt-sm-2',
			'mt-sm-3': 'mt-sm-3',
			'mt-sm-4': 'mt-sm-4',
			'mt-sm-5': 'mt-sm-5',
			'mb-sm-auto': 'mb-sm-auto',
			'mb-sm-0': 'mb-sm-0',
			'mb-sm-1': 'mb-sm-1',
			'mb-sm-2': 'mb-sm-2',
			'mb-sm-3': 'mb-sm-3',
			'mb-sm-4': 'mb-sm-4',
			'mb-sm-5': 'mb-sm-5',
			'mr-sm-auto': 'mr-sm-auto',
			'mr-sm-0': 'mr-sm-0',
			'mr-sm-1': 'mr-sm-1',
			'mr-sm-2': 'mr-sm-2',
			'mr-sm-3': 'mr-sm-3',
			'mr-sm-4': 'mr-sm-4',
			'mr-sm-5': 'mr-sm-5',
			'ml-sm-auto': 'ml-sm-auto',
			'ml-sm-0': 'ml-sm-0',
			'ml-sm-1': 'ml-sm-1',
			'ml-sm-2': 'ml-sm-2',
			'ml-sm-3': 'ml-sm-3',
			'ml-sm-4': 'ml-sm-4',
			'ml-sm-5': 'ml-sm-5',

			'm-md-auto': 'm-md-auto',
			'm-md-0': 'm-md-0',
			'm-md-1': 'm-md-1',
			'm-md-2': 'm-md-2',
			'm-md-3': 'm-md-3',
			'm-md-4': 'm-md-4',
			'm-md-5': 'm-md-5',
			'mt-md-auto': 'mt-md-auto',
			'mt-md-0': 'mt-md-0',
			'mt-md-1': 'mt-md-1',
			'mt-md-2': 'mt-md-2',
			'mt-md-3': 'mt-md-3',
			'mt-md-4': 'mt-md-4',
			'mt-md-5': 'mt-md-5',
			'mb-md-auto': 'mb-md-auto',
			'mb-md-0': 'mb-md-0',
			'mb-md-1': 'mb-md-1',
			'mb-md-2': 'mb-md-2',
			'mb-md-3': 'mb-md-3',
			'mb-md-4': 'mb-md-4',
			'mb-md-5': 'mb-md-5',
			'mr-md-auto': 'mr-md-auto',
			'mr-md-0': 'mr-md-0',
			'mr-md-1': 'mr-md-1',
			'mr-md-2': 'mr-md-2',
			'mr-md-3': 'mr-md-3',
			'mr-md-4': 'mr-md-4',
			'mr-md-5': 'mr-md-5',
			'ml-md-auto': 'ml-md-auto',
			'ml-md-0': 'ml-md-0',
			'ml-md-1': 'ml-md-1',
			'ml-md-2': 'ml-md-2',
			'ml-md-3': 'ml-md-3',
			'ml-md-4': 'ml-md-4',
			'ml-md-5': 'ml-md-5',

			'm-lg-auto': 'm-lg-auto',
			'm-lg-0': 'm-lg-0',
			'm-lg-1': 'm-lg-1',
			'm-lg-2': 'm-lg-2',
			'm-lg-3': 'm-lg-3',
			'm-lg-4': 'm-lg-4',
			'm-lg-5': 'm-lg-5',
			'mt-lg-auto': 'mt-lg-auto',
			'mt-lg-0': 'mt-lg-0',
			'mt-lg-1': 'mt-lg-1',
			'mt-lg-2': 'mt-lg-2',
			'mt-lg-3': 'mt-lg-3',
			'mt-lg-4': 'mt-lg-4',
			'mt-lg-5': 'mt-lg-5',
			'mb-lg-auto': 'mb-lg-auto',
			'mb-lg-0': 'mb-lg-0',
			'mb-lg-1': 'mb-lg-1',
			'mb-lg-2': 'mb-lg-2',
			'mb-lg-3': 'mb-lg-3',
			'mb-lg-4': 'mb-lg-4',
			'mb-lg-5': 'mb-lg-5',
			'mr-lg-auto': 'mr-lg-auto',
			'mr-lg-0': 'mr-lg-0',
			'mr-lg-1': 'mr-lg-1',
			'mr-lg-2': 'mr-lg-2',
			'mr-lg-3': 'mr-lg-3',
			'mr-lg-4': 'mr-lg-4',
			'mr-lg-5': 'mr-lg-5',
			'ml-lg-auto': 'ml-lg-auto',
			'ml-lg-0': 'ml-lg-0',
			'ml-lg-1': 'ml-lg-1',
			'ml-lg-2': 'ml-lg-2',
			'ml-lg-3': 'ml-lg-3',
			'ml-lg-4': 'ml-lg-4',
			'ml-lg-5': 'ml-lg-5',

			'm-xl-auto': 'm-xl-auto',
			'm-xl-0': 'm-xl-0',
			'm-xl-1': 'm-xl-1',
			'm-xl-2': 'm-xl-2',
			'm-xl-3': 'm-xl-3',
			'm-xl-4': 'm-xl-4',
			'm-xl-5': 'm-xl-5',
			'mt-xl-auto': 'mt-xl-auto',
			'mt-xl-0': 'mt-xl-0',
			'mt-xl-1': 'mt-xl-1',
			'mt-xl-2': 'mt-xl-2',
			'mt-xl-3': 'mt-xl-3',
			'mt-xl-4': 'mt-xl-4',
			'mt-xl-5': 'mt-xl-5',
			'mb-xl-auto': 'mb-xl-auto',
			'mb-xl-0': 'mb-xl-0',
			'mb-xl-1': 'mb-xl-1',
			'mb-xl-2': 'mb-xl-2',
			'mb-xl-3': 'mb-xl-3',
			'mb-xl-4': 'mb-xl-4',
			'mb-xl-5': 'mb-xl-5',
			'mr-xl-auto': 'mr-xl-auto',
			'mr-xl-0': 'mr-xl-0',
			'mr-xl-1': 'mr-xl-1',
			'mr-xl-2': 'mr-xl-2',
			'mr-xl-3': 'mr-xl-3',
			'mr-xl-4': 'mr-xl-4',
			'mr-xl-5': 'mr-xl-5',
			'ml-xl-auto': 'ml-xl-auto',
			'ml-xl-0': 'ml-xl-0',
			'ml-xl-1': 'ml-xl-1',
			'ml-xl-2': 'ml-xl-2',
			'ml-xl-3': 'ml-xl-3',
			'ml-xl-4': 'ml-xl-4',
			'ml-xl-5': 'ml-xl-5',

			'm-exl-auto': 'm-exl-auto',
			'm-exl-0': 'm-exl-0',
			'm-exl-1': 'm-exl-1',
			'm-exl-2': 'm-exl-2',
			'm-exl-3': 'm-exl-3',
			'm-exl-4': 'm-exl-4',
			'm-exl-5': 'm-exl-5',
			'mt-exl-auto': 'mt-exl-auto',
			'mt-exl-0': 'mt-exl-0',
			'mt-exl-1': 'mt-exl-1',
			'mt-exl-2': 'mt-exl-2',
			'mt-exl-3': 'mt-exl-3',
			'mt-exl-4': 'mt-exl-4',
			'mt-exl-5': 'mt-exl-5',
			'mb-exl-auto': 'mb-exl-auto',
			'mb-exl-0': 'mb-exl-0',
			'mb-exl-1': 'mb-exl-1',
			'mb-exl-2': 'mb-exl-2',
			'mb-exl-3': 'mb-exl-3',
			'mb-exl-4': 'mb-exl-4',
			'mb-exl-5': 'mb-exl-5',
			'mr-exl-auto': 'mr-exl-auto',
			'mr-exl-0': 'mr-exl-0',
			'mr-exl-1': 'mr-exl-1',
			'mr-exl-2': 'mr-exl-2',
			'mr-exl-3': 'mr-exl-3',
			'mr-exl-4': 'mr-exl-4',
			'mr-exl-5': 'mr-exl-5',
			'ml-exl-auto': 'ml-exl-auto',
			'ml-exl-0': 'ml-exl-0',
			'ml-exl-1': 'ml-exl-1',
			'ml-exl-2': 'ml-exl-2',
			'ml-exl-3': 'ml-exl-3',
			'ml-exl-4': 'ml-exl-4',
			'ml-exl-5': 'ml-exl-5',

			'my-auto': 'my-auto',
			'my-0': 'my-0',
			'my-1': 'my-1',
			'my-2': 'my-2',
			'my-3': 'my-3',
			'my-4': 'my-4',
			'my-5': 'my-5',
			'mx-auto': 'mx-auto',
			'mx-0': 'mx-0',
			'mx-1': 'mx-1',
			'mx-2': 'mx-2',
			'mx-3': 'mx-3',
			'mx-4': 'mx-4',
			'mx-5': 'mx-5',

			'my-xs-auto': 'my-xs-auto',
			'my-xs-0': 'my-xs-0',
			'my-xs-1': 'my-xs-1',
			'my-xs-2': 'my-xs-2',
			'my-xs-3': 'my-xs-3',
			'my-xs-4': 'my-xs-4',
			'my-xs-5': 'my-xs-5',
			'mx-xs-auto': 'mx-xs-auto',
			'mx-xs-0': 'mx-xs-0',
			'mx-xs-1': 'mx-xs-1',
			'mx-xs-2': 'mx-xs-2',
			'mx-xs-3': 'mx-xs-3',
			'mx-xs-4': 'mx-xs-4',
			'mx-xs-5': 'mx-xs-5',

			'my-sm-auto': 'my-sm-auto',
			'my-sm-0': 'my-sm-0',
			'my-sm-1': 'my-sm-1',
			'my-sm-2': 'my-sm-2',
			'my-sm-3': 'my-sm-3',
			'my-sm-4': 'my-sm-4',
			'my-sm-5': 'my-sm-5',
			'mx-sm-auto': 'mx-sm-auto',
			'mx-sm-0': 'mx-sm-0',
			'mx-sm-1': 'mx-sm-1',
			'mx-sm-2': 'mx-sm-2',
			'mx-sm-3': 'mx-sm-3',
			'mx-sm-4': 'mx-sm-4',
			'mx-sm-5': 'mx-sm-5',

			'my-md-auto': 'my-md-auto',
			'my-md-0': 'my-md-0',
			'my-md-1': 'my-md-1',
			'my-md-2': 'my-md-2',
			'my-md-3': 'my-md-3',
			'my-md-4': 'my-md-4',
			'my-md-5': 'my-md-5',
			'mx-md-auto': 'mx-md-auto',
			'mx-md-0': 'mx-md-0',
			'mx-md-1': 'mx-md-1',
			'mx-md-2': 'mx-md-2',
			'mx-md-3': 'mx-md-3',
			'mx-md-4': 'mx-md-4',
			'mx-md-5': 'mx-md-5',

			'my-lg-auto': 'my-lg-auto',
			'my-lg-0': 'my-lg-0',
			'my-lg-1': 'my-lg-1',
			'my-lg-2': 'my-lg-2',
			'my-lg-3': 'my-lg-3',
			'my-lg-4': 'my-lg-4',
			'my-lg-5': 'my-lg-5',
			'mx-lg-auto': 'mx-lg-auto',
			'mx-lg-0': 'mx-lg-0',
			'mx-lg-1': 'mx-lg-1',
			'mx-lg-2': 'mx-lg-2',
			'mx-lg-3': 'mx-lg-3',
			'mx-lg-4': 'mx-lg-4',
			'mx-lg-5': 'mx-lg-5',

			'my-xl-auto': 'my-xl-auto',
			'my-xl-0': 'my-xl-0',
			'my-xl-1': 'my-xl-1',
			'my-xl-2': 'my-xl-2',
			'my-xl-3': 'my-xl-3',
			'my-xl-4': 'my-xl-4',
			'my-xl-5': 'my-xl-5',
			'mx-xl-auto': 'mx-xl-auto',
			'mx-xl-0': 'mx-xl-0',
			'mx-xl-1': 'mx-xl-1',
			'mx-xl-2': 'mx-xl-2',
			'mx-xl-3': 'mx-xl-3',
			'mx-xl-4': 'mx-xl-4',
			'mx-xl-5': 'mx-xl-5',

			'my-exl-auto': 'my-exl-auto',
			'my-exl-0': 'my-exl-0',
			'my-exl-1': 'my-exl-1',
			'my-exl-2': 'my-exl-2',
			'my-exl-3': 'my-exl-3',
			'my-exl-4': 'my-exl-4',
			'my-exl-5': 'my-exl-5',
			'mx-exl-auto': 'mx-exl-auto',
			'mx-exl-0': 'mx-exl-0',
			'mx-exl-1': 'mx-exl-1',
			'mx-exl-2': 'mx-exl-2',
			'mx-exl-3': 'mx-exl-3',
			'mx-exl-4': 'mx-exl-4',
			'mx-exl-5': 'mx-exl-5',
		}

		this.paddingclasses = {
			'p-auto': 'p-auto',
			'p-0': 'p-0',
			'p-1': 'p-1',
			'p-2': 'p-2',
			'p-3': 'p-3',
			'p-4': 'p-4',
			'p-5': 'p-5',
			'pt-auto': 'pt-auto',
			'pt-0': 'pt-0',
			'pt-1': 'pt-1',
			'pt-2': 'pt-2',
			'pt-3': 'pt-3',
			'pt-4': 'pt-4',
			'pt-5': 'pt-5',
			'pb-auto': 'pb-auto',
			'pb-0': 'pb-0',
			'pb-1': 'pb-1',
			'pb-2': 'pb-2',
			'pb-3': 'pb-3',
			'pb-4': 'pb-4',
			'pb-5': 'pb-5',
			'pr-auto': 'pr-auto',
			'pr-0': 'pr-0',
			'pr-1': 'pr-1',
			'pr-2': 'pr-2',
			'pr-3': 'pr-3',
			'pr-4': 'pr-4',
			'pr-5': 'pr-5',
			'pl-auto': 'pl-auto',
			'pl-0': 'pl-0',
			'pl-1': 'pl-1',
			'pl-2': 'pl-2',
			'pl-3': 'pl-3',
			'pl-4': 'pl-4',
			'pl-5': 'pl-5',

			'p-xs-auto': 'p-xs-auto',
			'p-xs-0': 'p-xs-0',
			'p-xs-1': 'p-xs-1',
			'p-xs-2': 'p-xs-2',
			'p-xs-3': 'p-xs-3',
			'p-xs-4': 'p-xs-4',
			'p-xs-5': 'p-xs-5',
			'pt-xs-auto': 'pt-xs-auto',
			'pt-xs-0': 'pt-xs-0',
			'pt-xs-1': 'pt-xs-1',
			'pt-xs-2': 'pt-xs-2',
			'pt-xs-3': 'pt-xs-3',
			'pt-xs-4': 'pt-xs-4',
			'pt-xs-5': 'pt-xs-5',
			'pb-xs-auto': 'pb-xs-auto',
			'pb-xs-0': 'pb-xs-0',
			'pb-xs-1': 'pb-xs-1',
			'pb-xs-2': 'pb-xs-2',
			'pb-xs-3': 'pb-xs-3',
			'pb-xs-4': 'pb-xs-4',
			'pb-xs-5': 'pb-xs-5',
			'pr-xs-auto': 'pr-xs-auto',
			'pr-xs-0': 'pr-xs-0',
			'pr-xs-1': 'pr-xs-1',
			'pr-xs-2': 'pr-xs-2',
			'pr-xs-3': 'pr-xs-3',
			'pr-xs-4': 'pr-xs-4',
			'pr-xs-5': 'pr-xs-5',
			'pl-xs-auto': 'pl-xs-auto',
			'pl-xs-0': 'pl-xs-0',
			'pl-xs-1': 'pl-xs-1',
			'pl-xs-2': 'pl-xs-2',
			'pl-xs-3': 'pl-xs-3',
			'pl-xs-4': 'pl-xs-4',
			'pl-xs-5': 'pl-xs-5',

			'p-sm-auto': 'p-sm-auto',
			'p-sm-0': 'p-sm-0',
			'p-sm-1': 'p-sm-1',
			'p-sm-2': 'p-sm-2',
			'p-sm-3': 'p-sm-3',
			'p-sm-4': 'p-sm-4',
			'p-sm-5': 'p-sm-5',
			'pt-sm-auto': 'pt-sm-auto',
			'pt-sm-0': 'pt-sm-0',
			'pt-sm-1': 'pt-sm-1',
			'pt-sm-2': 'pt-sm-2',
			'pt-sm-3': 'pt-sm-3',
			'pt-sm-4': 'pt-sm-4',
			'pt-sm-5': 'pt-sm-5',
			'pb-sm-auto': 'pb-sm-auto',
			'pb-sm-0': 'pb-sm-0',
			'pb-sm-1': 'pb-sm-1',
			'pb-sm-2': 'pb-sm-2',
			'pb-sm-3': 'pb-sm-3',
			'pb-sm-4': 'pb-sm-4',
			'pb-sm-5': 'pb-sm-5',
			'pr-sm-auto': 'pr-sm-auto',
			'pr-sm-0': 'pr-sm-0',
			'pr-sm-1': 'pr-sm-1',
			'pr-sm-2': 'pr-sm-2',
			'pr-sm-3': 'pr-sm-3',
			'pr-sm-4': 'pr-sm-4',
			'pr-sm-5': 'pr-sm-5',
			'pl-sm-auto': 'pl-sm-auto',
			'pl-sm-0': 'pl-sm-0',
			'pl-sm-1': 'pl-sm-1',
			'pl-sm-2': 'pl-sm-2',
			'pl-sm-3': 'pl-sm-3',
			'pl-sm-4': 'pl-sm-4',
			'pl-sm-5': 'pl-sm-5',

			'p-md-auto': 'p-md-auto',
			'p-md-0': 'p-md-0',
			'p-md-1': 'p-md-1',
			'p-md-2': 'p-md-2',
			'p-md-3': 'p-md-3',
			'p-md-4': 'p-md-4',
			'p-md-5': 'p-md-5',
			'pt-md-auto': 'pt-md-auto',
			'pt-md-0': 'pt-md-0',
			'pt-md-1': 'pt-md-1',
			'pt-md-2': 'pt-md-2',
			'pt-md-3': 'pt-md-3',
			'pt-md-4': 'pt-md-4',
			'pt-md-5': 'pt-md-5',
			'pb-md-auto': 'pb-md-auto',
			'pb-md-0': 'pb-md-0',
			'pb-md-1': 'pb-md-1',
			'pb-md-2': 'pb-md-2',
			'pb-md-3': 'pb-md-3',
			'pb-md-4': 'pb-md-4',
			'pb-md-5': 'pb-md-5',
			'pr-md-auto': 'pr-md-auto',
			'pr-md-0': 'pr-md-0',
			'pr-md-1': 'pr-md-1',
			'pr-md-2': 'pr-md-2',
			'pr-md-3': 'pr-md-3',
			'pr-md-4': 'pr-md-4',
			'pr-md-5': 'pr-md-5',
			'pl-md-auto': 'pl-md-auto',
			'pl-md-0': 'pl-md-0',
			'pl-md-1': 'pl-md-1',
			'pl-md-2': 'pl-md-2',
			'pl-md-3': 'pl-md-3',
			'pl-md-4': 'pl-md-4',
			'pl-md-5': 'pl-md-5',

			'p-lg-auto': 'p-lg-auto',
			'p-lg-0': 'p-lg-0',
			'p-lg-1': 'p-lg-1',
			'p-lg-2': 'p-lg-2',
			'p-lg-3': 'p-lg-3',
			'p-lg-4': 'p-lg-4',
			'p-lg-5': 'p-lg-5',
			'pt-lg-auto': 'pt-lg-auto',
			'pt-lg-0': 'pt-lg-0',
			'pt-lg-1': 'pt-lg-1',
			'pt-lg-2': 'pt-lg-2',
			'pt-lg-3': 'pt-lg-3',
			'pt-lg-4': 'pt-lg-4',
			'pt-lg-5': 'pt-lg-5',
			'pb-lg-auto': 'pb-lg-auto',
			'pb-lg-0': 'pb-lg-0',
			'pb-lg-1': 'pb-lg-1',
			'pb-lg-2': 'pb-lg-2',
			'pb-lg-3': 'pb-lg-3',
			'pb-lg-4': 'pb-lg-4',
			'pb-lg-5': 'pb-lg-5',
			'pr-lg-auto': 'pr-lg-auto',
			'pr-lg-0': 'pr-lg-0',
			'pr-lg-1': 'pr-lg-1',
			'pr-lg-2': 'pr-lg-2',
			'pr-lg-3': 'pr-lg-3',
			'pr-lg-4': 'pr-lg-4',
			'pr-lg-5': 'pr-lg-5',
			'pl-lg-auto': 'pl-lg-auto',
			'pl-lg-0': 'pl-lg-0',
			'pl-lg-1': 'pl-lg-1',
			'pl-lg-2': 'pl-lg-2',
			'pl-lg-3': 'pl-lg-3',
			'pl-lg-4': 'pl-lg-4',
			'pl-lg-5': 'pl-lg-5',

			'p-xl-auto': 'p-xl-auto',
			'p-xl-0': 'p-xl-0',
			'p-xl-1': 'p-xl-1',
			'p-xl-2': 'p-xl-2',
			'p-xl-3': 'p-xl-3',
			'p-xl-4': 'p-xl-4',
			'p-xl-5': 'p-xl-5',
			'pt-xl-auto': 'pt-xl-auto',
			'pt-xl-0': 'pt-xl-0',
			'pt-xl-1': 'pt-xl-1',
			'pt-xl-2': 'pt-xl-2',
			'pt-xl-3': 'pt-xl-3',
			'pt-xl-4': 'pt-xl-4',
			'pt-xl-5': 'pt-xl-5',
			'pb-xl-auto': 'pb-xl-auto',
			'pb-xl-0': 'pb-xl-0',
			'pb-xl-1': 'pb-xl-1',
			'pb-xl-2': 'pb-xl-2',
			'pb-xl-3': 'pb-xl-3',
			'pb-xl-4': 'pb-xl-4',
			'pb-xl-5': 'pb-xl-5',
			'pr-xl-auto': 'pr-xl-auto',
			'pr-xl-0': 'pr-xl-0',
			'pr-xl-1': 'pr-xl-1',
			'pr-xl-2': 'pr-xl-2',
			'pr-xl-3': 'pr-xl-3',
			'pr-xl-4': 'pr-xl-4',
			'pr-xl-5': 'pr-xl-5',
			'pl-xl-auto': 'pl-xl-auto',
			'pl-xl-0': 'pl-xl-0',
			'pl-xl-1': 'pl-xl-1',
			'pl-xl-2': 'pl-xl-2',
			'pl-xl-3': 'pl-xl-3',
			'pl-xl-4': 'pl-xl-4',
			'pl-xl-5': 'pl-xl-5',

			'p-exl-auto': 'p-exl-auto',
			'p-exl-0': 'p-exl-0',
			'p-exl-1': 'p-exl-1',
			'p-exl-2': 'p-exl-2',
			'p-exl-3': 'p-exl-3',
			'p-exl-4': 'p-exl-4',
			'p-exl-5': 'p-exl-5',
			'pt-exl-auto': 'pt-exl-auto',
			'pt-exl-0': 'pt-exl-0',
			'pt-exl-1': 'pt-exl-1',
			'pt-exl-2': 'pt-exl-2',
			'pt-exl-3': 'pt-exl-3',
			'pt-exl-4': 'pt-exl-4',
			'pt-exl-5': 'pt-exl-5',
			'pb-exl-auto': 'pb-exl-auto',
			'pb-exl-0': 'pb-exl-0',
			'pb-exl-1': 'pb-exl-1',
			'pb-exl-2': 'pb-exl-2',
			'pb-exl-3': 'pb-exl-3',
			'pb-exl-4': 'pb-exl-4',
			'pb-exl-5': 'pb-exl-5',
			'pr-exl-auto': 'pr-exl-auto',
			'pr-exl-0': 'pr-exl-0',
			'pr-exl-1': 'pr-exl-1',
			'pr-exl-2': 'pr-exl-2',
			'pr-exl-3': 'pr-exl-3',
			'pr-exl-4': 'pr-exl-4',
			'pr-exl-5': 'pr-exl-5',
			'pl-exl-auto': 'pl-exl-auto',
			'pl-exl-0': 'pl-exl-0',
			'pl-exl-1': 'pl-exl-1',
			'pl-exl-2': 'pl-exl-2',
			'pl-exl-3': 'pl-exl-3',
			'pl-exl-4': 'pl-exl-4',
			'pl-exl-5': 'pl-exl-5',

			'py-auto': 'py-auto',
			'py-0': 'py-0',
			'py-1': 'py-1',
			'py-2': 'py-2',
			'py-3': 'py-3',
			'py-4': 'py-4',
			'py-5': 'py-5',
			'px-auto': 'px-auto',
			'px-0': 'px-0',
			'px-1': 'px-1',
			'px-2': 'px-2',
			'px-3': 'px-3',
			'px-4': 'px-4',
			'px-5': 'px-5',

			'py-xs-auto': 'py-xs-auto',
			'py-xs-0': 'py-xs-0',
			'py-xs-1': 'py-xs-1',
			'py-xs-2': 'py-xs-2',
			'py-xs-3': 'py-xs-3',
			'py-xs-4': 'py-xs-4',
			'py-xs-5': 'py-xs-5',
			'px-xs-auto': 'px-xs-auto',
			'px-xs-0': 'px-xs-0',
			'px-xs-1': 'px-xs-1',
			'px-xs-2': 'px-xs-2',
			'px-xs-3': 'px-xs-3',
			'px-xs-4': 'px-xs-4',
			'px-xs-5': 'px-xs-5',

			'py-sm-auto': 'py-sm-auto',
			'py-sm-0': 'py-sm-0',
			'py-sm-1': 'py-sm-1',
			'py-sm-2': 'py-sm-2',
			'py-sm-3': 'py-sm-3',
			'py-sm-4': 'py-sm-4',
			'py-sm-5': 'py-sm-5',
			'px-sm-auto': 'px-sm-auto',
			'px-sm-0': 'px-sm-0',
			'px-sm-1': 'px-sm-1',
			'px-sm-2': 'px-sm-2',
			'px-sm-3': 'px-sm-3',
			'px-sm-4': 'px-sm-4',
			'px-sm-5': 'px-sm-5',

			'py-md-auto': 'py-md-auto',
			'py-md-0': 'py-md-0',
			'py-md-1': 'py-md-1',
			'py-md-2': 'py-md-2',
			'py-md-3': 'py-md-3',
			'py-md-4': 'py-md-4',
			'py-md-5': 'py-md-5',
			'px-md-auto': 'px-md-auto',
			'px-md-0': 'px-md-0',
			'px-md-1': 'px-md-1',
			'px-md-2': 'px-md-2',
			'px-md-3': 'px-md-3',
			'px-md-4': 'px-md-4',
			'px-md-5': 'px-md-5',

			'py-lg-auto': 'py-lg-auto',
			'py-lg-0': 'py-lg-0',
			'py-lg-1': 'py-lg-1',
			'py-lg-2': 'py-lg-2',
			'py-lg-3': 'py-lg-3',
			'py-lg-4': 'py-lg-4',
			'py-lg-5': 'py-lg-5',
			'px-lg-auto': 'px-lg-auto',
			'px-lg-0': 'px-lg-0',
			'px-lg-1': 'px-lg-1',
			'px-lg-2': 'px-lg-2',
			'px-lg-3': 'px-lg-3',
			'px-lg-4': 'px-lg-4',
			'px-lg-5': 'px-lg-5',

			'py-xl-auto': 'py-xl-auto',
			'py-xl-0': 'py-xl-0',
			'py-xl-1': 'py-xl-1',
			'py-xl-2': 'py-xl-2',
			'py-xl-3': 'py-xl-3',
			'py-xl-4': 'py-xl-4',
			'py-xl-5': 'py-xl-5',
			'px-xl-auto': 'px-xl-auto',
			'px-xl-0': 'px-xl-0',
			'px-xl-1': 'px-xl-1',
			'px-xl-2': 'px-xl-2',
			'px-xl-3': 'px-xl-3',
			'px-xl-4': 'px-xl-4',
			'px-xl-5': 'px-xl-5',

			'py-exl-auto': 'py-exl-auto',
			'py-exl-0': 'py-exl-0',
			'py-exl-1': 'py-exl-1',
			'py-exl-2': 'py-exl-2',
			'py-exl-3': 'py-exl-3',
			'py-exl-4': 'py-exl-4',
			'py-exl-5': 'py-exl-5',
			'px-exl-auto': 'px-exl-auto',
			'px-exl-0': 'px-exl-0',
			'px-exl-1': 'px-exl-1',
			'px-exl-2': 'px-exl-2',
			'px-exl-3': 'px-exl-3',
			'px-exl-4': 'px-exl-4',
			'px-exl-5': 'px-exl-5',
		}

		this.priorityseverityclasses = {
			'High': 'high',
			'Medium': 'medium',
			'Low': 'low',
			'None': 'none'
		}

		
		this.colorPickerColorsArray = [
			'#28C76F', '#EA5455', '#FF9F43', '#00ffff',
			'#9B59B6', '#FDCF17', '#A8AAAE', '#000000', '#4B465C',
			'#6610F2', '#0500ff', '#20C997'
		]

		this.recordinfomodalsize = this.getModalsizeclasses()['xs']
	}

	getHtmlcontorls() {
		return this.htmlcontorls
	}
	getModalsizeclasses() {
		return this.modalsizeclasses
	}
	getGridclasses() {
		return this.gridclasses
	}
	getSidebarsizeclasses() {
		return this.sidebarsizeclasses
	}
	getMarginclasses() {
		return this.marginclasses
	}
	getPaddingclasses() {
		return this.paddingclasses
	}
	getPrioritySeverityclasses() {
		return this.priorityseverityclasses
	}

	getErrmsg() {
		return this.errmsg
	}

	getEmailTypes() {
		return this.emailtypes
	}

	getRecordinfomodalsize() {
		return this.recordinfomodalsize
	}

	getRegex() {
		return this.regex
	}

	getMssqldefval() {
		return this.weburl
	}

	getMysqldefval() {
		return this.weburl
	}

	getWeburl() {
		return this.weburl
	}

	setWeburl(weburl) {
		this.weburl = weburl
	}

	getEndpointverson() {
		return this.endpointverson
	}

	setEndpointverson(endpointverson) {
		this.endpointverson = endpointverson
	}

	getEndpointurl() {
		return this.endpointurl
	}

	setEndpointurl(endpointurl) {
		this.endpointurl = endpointurl
	}

	getDocurl() {
		return this.docurl
	}

	setDocurl(docurl) {
		this.docurl = docurl
	}

	getRegdomain() {
		return this.regdomain
	}

	setRegdomain(regdomain) {
		this.regdomain = regdomain
	}

	getMailurl() {
		return this.mailurl
	}

	setMailurl(mailurl) {
		this.mailurl = mailurl
	}

	getProtocol() {
		return this.protocol
	}

	getGreetings() {
		return this.greetingmessages
	}
	getDefaulImageUploadUrl() {
		return this.defaultimageupload
	}
	getDefaultCandidateImageUploadUrl() {
		return this.defaultCanidateimgupload
	}
	setProtocol(protocol) {
		this.protocol = protocol
	}

	getDocpath() {
		return this.docpath
	}

	setDocpath(docpath) {
		this.docpath = docpath
	}

	getCdnurl() {
		return this.cdnurl
	}

	setCdnurl(cdnurl) {
		this.cdnurl = cdnurl
	}

	getServermode() {
		return this.servermode
	}

	setServermode(servermode) {
		this.servermode = servermode
	}

	getMainDirpath() {
		return this.maindirpath
	}

	setMainDirpath(maindirpath) {
		this.maindirpath = maindirpath
	}

	getDirpath() {
		return this.dirpath
	}

	setDirpath(dirpath) {
		this.dirpath = dirpath
	}

	getMainDB() {
		return this.MainDB
	}

	setMainDB(MainDB) {
		this.MainDB = MainDB
	}

	getDB() {
		return this.DB
	}

	setDB(DB) {
		this.DB = DB
	}

	getMainDBUser() {
		return this.MainDBUser
	}

	setMainDBUser(MainDBUser) {
		this.MainDBUser = MainDBUser
	}

	getDBUser() {
		return this.DBUser
	}

	setDBUser(DBUser) {
		this.DBUser = DBUser
	}

	getMainDBHost() {
		return this.MainDBHost
	}

	setMainDBHost(MainDBHost) {
		this.MainDBHost = MainDBHost
	}

	getDBHost() {
		return this.DBHost
	}

	setDBHost(DBHost) {
		this.DBHost = DBHost
	}

	getMainDBPass() {
		return this.MainDBPass
	}

	setMainDBPass(MainDBPass) {
		this.MainDBPass = MainDBPass
	}

	getDBPass() {
		return this.DBPass
	}

	setDBPass(DBPass) {
		this.DBPass = DBPass
	}

	getMainDBPort() {
		return this.MainDBPort
	}

	setMainDBPort(MainDBPort) {
		this.MainDBPort = MainDBPort
	}

	getDBPort() {
		return this.DBPort
	}

	setDBPort(DBPort) {
		this.DBPort = DBPort
	}

	getDBType() {
		return this.DBType
	}

	setDBType(DBType) {
		this.DBType = DBType
	}

	getSessionName() {
		return this.sessionname
	}

	getAdminutype() {
		return this.adminutype
	}

	getSaveSidebar() {
		return this.savesidebar
	}

	getSubmitbutton() {
		return this.submitbutton
	}

	getGeneratereport() {
		return this.generatereport
	}

	getApplyFilterSidebar() {
		return this.applyfiltersidebar
	}

	getUpdateSidebar() {
		return this.updatesidebar
	}

	getResetSidebar() {
		return this.resetsidebar
	}

	getCloseSidebar() {
		return this.closesidebar
	}

	getUpdateMyProfile() {
		return this.updatemyprofile
	}

	getChangePassword() {
		return this.changepassword
	}

	getChangeUsertype() {
		return this.changeusertype
	}

	getPage404url() {
		return this.page404url
	}

	getImageurl() {
		return this.imageurl
	}

	getNoDataFound() {
		return this.nodatafound
	}

	getReporturl() {
		return this.reporturl
	}

	getCmpRegisterId() {
		return this.cmpregisterid
	}

	getCmpUnRegisterId() {
		return this.cmpunregisterid
	}

	getCompositionSchemeId() {
		return this.cmpcompositionschemeid
	}




	getStateId() {
		return this.stateid
	}

	getDefaultBirthDate() {
		return this.defaultbirthdate
	}

	getAdminUserId() {
		return this.adminuid
	}

	getDefaultCmpId() {
		return this.defaultcmpid
	}

	setDefaultCmpId(defaultcmpid) {
		this.defaultcmpid = defaultcmpid
	}



	getDefaultImageurl() {
		return this.defaultimageurl
	}

	getDefaultCatImageurl() {
		return this.defaultcatimageurl
	}

	getPaytypeRupeeimageurl() {
		return this.paytyperupeeimageurl
	}

	getDefaultNotiImageurl() {
		return this.defaultnotiimageurl
	}
	getSocketurl() {
		return this.socketurl
	}

	getSocketserver() {
		return this.socketserver
	}

	getSecretKey() {
		return this.secretkey
	}

	getFollowupEmailId() {
		return this.followupemail
	}

	getFollowupMessageId() {
		return this.followupmessage
	}

	getFollowupCallId() {
		return this.followupcall
	}


	getDaysName() {
		return this.daysName
	}

	getDaysMasterDataArray() {
		return this.daysMasterData
	}

	getBgColorsArray() {
		return this.bgColorsArray
	}

	getUnAssignedColumnId() {
		return this.unassignedcolumnid
	}

}
