import React, { useState, useEffect, useRef } from 'react'
import login_bg from "../assets/images/login-bg.png"
import big_logo from "../assets/images/big-logo.png"

import { Config, JsCall, SVG } from '../config/IISMethods'

import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import Spinner from './Components/Spinner'
import bizcompasslogo from "../assets/images/BizcompassLogoNew.svg"
import PasswordTooltip from './Components/PasswordTooltip'
import '../assets/main-css/default-config-style.css'
import '../assets/main-css/style.scss'
import DualAuthRecovery from './Components/DualAuthRecovery'
import { store } from './../index';
import ActionIcons from './Components/ActionIcons'

const Index = (props) => {

    const getCurrentState = () => {
        return store.getState()
    }


    const { inputRef, otpState, otpError, handleOTPChange } = props

    const [checknumber, setchecknumber] = useState(false)
    const [checkspecialcharacter, setcheckspecialcharacter] = useState(false)
    const [checkuppercase, setcheckuppercase] = useState(false)
    const [checklength, setchecklength] = useState(false)
    const [checklowercase, setchecklowercase] = useState(false)
    const [showIndicator, setshowIndicator] = useState(false)

    const handleForm = (e) => {
        e.preventDefault()
        const element1 = document.querySelector("[class='login-wrpper']")
        const element2 = document.querySelector("[class='login-wrpper d-none']")
        element1.classList.toggle('d-none')
        element2.classList.toggle('d-none')
    }

    const handlePasswordField = (id) => {
        var element = document.getElementById('form-' + id)
        element.type = (element.type === "text" ? "password" : "text")
        element = element.parentNode.childNodes[3].childNodes[0]
        element.innerHTML = (element.innerHTML === 'visibility' ? 'visibility_off' : 'visibility')
    }

    const ChangeIndicator = () => {

        let passwordvalue = props.state.otpData.newpassword

        var validInputs = ["#", "/", "!", "@", "#", "$", "%", "^", "&", "*", "_"]
        if ((passwordvalue.length >= 8)) {
            setchecklength(true)
        } else {
            setchecklength(false)
        }
        if ((/[0-9]/.test(passwordvalue))) {
            setchecknumber(true)
        } else {
            setchecknumber(false)
        }

        if ((/[A-Z]/.test(passwordvalue))) {
            setcheckuppercase(true)
        } else {
            setcheckuppercase(false)
        }

        if ((/[a-z]/.test(passwordvalue))) {
            setchecklowercase(true)
        } else {
            setchecklowercase(false)
        }

        var a = validInputs.map((item) => {
            if (passwordvalue.includes(item)) {
                return true
            } else {
                return false
            }
        })

        if (a.includes(true)) {
            setcheckspecialcharacter(true)
        } else {
            setcheckspecialcharacter(false)
        }
    }

    useEffect(() => {
        if (props.is2FA) {
            setTimeout(() => {
                if (inputRef[0].current) {
                    inputRef[0].current.focus()
                }
            }, 100)
        }
    }, [props.is2FA])

    return (
        <>

            <div>
                <div className="app-admin-wrap layout-sidebar-compact sidebar-dark sidenav-open clearfix">
                    <div className="main-content-login">
                        <div className="row m-0">
                            <div className="col-lg-5 my-auto">
                                <div className="body-bg d-none d-lg-block">
                                    <figure>
                                        <img src={login_bg} />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7">
                                <div className="login-wrpper flex-column">
                                    <div className="login-inner-wrapper">
                                        <div className="login-card">
                                            <div className="login-header">
                                                <figure>
                                                    {/* <img src={bizcompasslogo} /> */}
                                                    <span>{SVG.bizcompasslogonewIcon({width: 450, height: 65})}</span> {/* Take svg icons from its component of SVG from IISMethods | Priyanshi Sarvan ~  2024-10-14 ~ 10:27 AM */}
                                                </figure>
                                            </div>
                                            <form className="signup-input">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">

                                                            {
                                                                // forgot password form
                                                                props.state.forgetData.forgetpassword ?
                                                                    <>
                                                                        <div className="col-12">
                                                                            <div className="form-group validate-input">
                                                                                <label className="label-form-control label-form-control-lg">Enter Your Work Email Id<span className="text-danger"> * </span></label>
                                                                                <input className="form-control form-control-lg" placeholder={`Enter Email Id`} id="form-email" onChange={(e) => props.handleForgetPassword(e)} name="email" type="text" value={props.state.forgetData.email} />
                                                                                <label className="input-group-text-lg" htmlFor="forgetpassword">
                                                                                    <ActionIcons type="email" />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-auto w-100 d-flex justify-content-end">
                                                                            <h6 className="text-right" onClick={(e) => { e.preventDefault(); JsCall.handleError('form-email', false); props.setState({ ...props.state, forgetData: { ...props.state.forgetData, forgetpassword: !props.state.forgetData.forgetpassword } }) }}>
                                                                                <a href="" className="text-decoration-underline text-primary">Sign In</a>
                                                                            </h6>
                                                                        </div>
                                                                    </>
                                                                    // forgot password form
                                                                    :
                                                                    // otp varification
                                                                    props.state.otpData.verifyotp ?
                                                                        <>
                                                                            <div className="col-12">
                                                                                <div className="form-group validate-input">
                                                                                    <label className="label-form-control label-form-control-lg">Enter Your Otp<span className="text-danger"> * </span></label>
                                                                                    <input className="form-control form-control-lg" placeholder={`Enter Otp`} id="form-otp" onChange={(e) => props.handleOtp(e)} name="otp" type="number" value={props.state.otpData.otp} />
                                                                                    <label className="input-group-text-lg" htmlFor="otp">
                                                                                    <ActionIcons type="verified" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="form-group validate-input">
                                                                                    <label className="label-form-control label-form-control-lg">Enter Your New Password<span className="text-danger"> * </span> <PasswordTooltip /> </label>
                                                                                    <input className="form-control form-control-lg" placeholder={`******`} onChange={async (e) => { await props.handleOtp(e); await ChangeIndicator() }} onFocus={() => { setshowIndicator(true) }} id="form-newpassword" name="newpassword" type="password" value={props.loginData.newpassword} />
                                                                                    <label className="input-group-text-lg" htmlFor="newpassword">
                                                                                        <ActionIcons type='lock' />
                                                                                    </label>
                                                                                    <label className="input-group-text-lg input-group-right" htmlFor="form-loginpassword">
                                                                                        <ActionIcons type="visibilityfill" onClick={() => handlePasswordField("newpassword")} />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="form-group validate-input">
                                                                                    <label className="label-form-control label-form-control-lg">Enter Your Confirm Password<span className="text-danger"> * </span></label>
                                                                                    <input className="form-control form-control-lg" placeholder={`******`} onChange={(e) => props.handleOtp(e)} id="form-confirmnewpassword" name="confirmnewpassword" type="password" value={props.loginData.confirmnewpassword} />
                                                                                    <label className="input-group-text-lg" htmlFor="confirmnewpassword">
                                                                                        <ActionIcons type='lock' />
                                                                                    </label>
                                                                                    <label className="input-group-text-lg input-group-right" htmlFor="form-loginpassword">
                                                                                        <ActionIcons type="visibilityfill" onClick={() => handlePasswordField("confirmnewpassword")} />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 mb-2">
                                                                                {
                                                                                    showIndicator ?
                                                                                        <>
                                                                                            {
                                                                                                !checklength ?
                                                                                                    <li className={`user-list d-flex ml-3`}><span className={`material-icons-outlined text-18 mr-3 mt-1 `}>check_circle_outline</span><p className="mb-0 mt-2">Must Be at least 8 characters</p></li>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                            {
                                                                                                !checknumber ?
                                                                                                    <li className={`user-list d-flex ml-3`}><span className={`material-icons-outlined text-18 mr-3 mt-1`}>check_circle_outline</span><p className="mb-0 mt-2">Have at least one number</p></li>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                            {
                                                                                                !checkspecialcharacter ?
                                                                                                    <li className={`user-list d-flex ml-3`}><span className={`material-icons-outlined text-18 mr-3 mt-1`}>check_circle_outline</span><p className="mb-0 mt-2">Have at least one symbol !@#$%^&*_</p></li>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                            {
                                                                                                !checkuppercase ?
                                                                                                    <li className={`user-list d-flex ml-3 `}><span className={`material-icons-outlined text-18 mr-3 mt-1`}>check_circle_outline</span><p className="mb-0 mt-2">Have at least one upper case letter</p></li>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                            {
                                                                                                !checklowercase ?
                                                                                                    <li className={`user-list d-flex ml-3`}><span className={`material-icons-outlined text-18 mr-3 mt-1`}>check_circle_outline</span><p className="mb-0 mt-2">Have at least one lower case letter</p></li>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        // otp varification
                                                                        :
                                                                        // log in form
                                                                        <>
                                                                            {
                                                                                !props.is2FA ?
                                                                                    <>
                                                                                        <div className="col-12">
                                                                                            <div className="form-group validate-input">
                                                                                                <label className="label-form-control label-form-control-lg">Username<span className="text-danger"> * </span></label>
                                                                                                <div className="position-relative">
                                                                                                    <input className="form-control form-control-lg" placeholder={`Enter Username`} onChange={props.handleSignIn} id="form-username" name="username" type="text" value={props.loginData.username} />
                                                                                                    <label className="input-group-text-lg p-0" htmlFor="username" style={{ top: '50%', left: '11px', transform: 'translateY(-50%)' }}>
                                                                                                        <ActionIcons type='person' />
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="form-group validate-input">
                                                                                                <label className="label-form-control label-form-control-lg">Password
                                                                                                    <span className="text-danger"> * </span>
                                                                                                    {/* <PasswordTooltip /> */}
                                                                                                </label>
                                                                                                <div className="position-relative">
                                                                                                    <input className="form-control form-control-lg" placeholder={`******`} onChange={props.handleSignIn} id="form-loginpassword" name="loginpassword" type="password" value={props.loginData.loginpassword} />
                                                                                                    <label className="input-group-text-lg p-0" htmlFor="password" style={{ top: '50%', left: '11px', transform: 'translateY(-50%)' }}>
                                                                                                        <ActionIcons type='lock' />
                                                                                                    </label>
                                                                                                </div>
                                                                                                <label className="input-group-text-lg input-group-right" htmlFor="form-loginpassword">
                                                                                                    <ActionIcons type="visibilityfill" onClick={() => handlePasswordField("loginpassword")} />
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {
                                                                                props.is2FA ?
                                                                                    <div className="login-card forgetpass-content otp-content" id='otp-block'>
                                                                                        <form className="signup-input my-5">
                                                                                            <div className='row'>
                                                                                                <div className='col-12'>
                                                                                                    <div className='login-header-content text-center'>
                                                                                                        <h4 className='mb-2 text-18 font-weight-600'>Two-Factor Authentication Code</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-12'>
                                                                                                    <div className='d-flex'>
                                                                                                        <div className='mx-auto'>
                                                                                                            {inputRef.map((otpRef, index, otpArr) => {
                                                                                                                return <span className={`${otpArr.length !== (index + 1) ? 'pr-1' : ''}`}>
                                                                                                                    <input
                                                                                                                        ref={otpRef}
                                                                                                                        className={`otp-input ${otpError[`otp${index + 1}`] ? 'error' : ''}`}
                                                                                                                        name={`otp${index + 1}`}
                                                                                                                        type='text'
                                                                                                                        pattern='[0-9]*'
                                                                                                                        onKeyUp={(e) => {
                                                                                                                            if (e.key === 'Enter') {
                                                                                                                                props.setState({ ...props.state, addButtonDisable: true }); props.signIn(e)
                                                                                                                            }
                                                                                                                            else if (e.key === 'Backspace') {
                                                                                                                                inputRef[index - 1].current.focus()
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        onKeyPress={(e) => {
                                                                                                                            if (!(e.which >= 48 && e.which <= 57)) e.preventDefault()
                                                                                                                        }}
                                                                                                                        maxLength="1"
                                                                                                                        value={otpState[index + 5]}
                                                                                                                        onFocus={(e) => e.target.select()}
                                                                                                                        onChange={({ target }) => handleOTPChange('number-input', target.name, target.value, index)}
                                                                                                                    />
                                                                                                                    {index === 2 && <span className='text-gray-300 font-weight-600 my-auto ml-1'>-</span>}
                                                                                                                </span>
                                                                                                            })}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="text-center mt-2">
                                                                                                <h6 className="text-center"><a href={void (0)} onClick={() => props.handleGrid(true, "modal", 'recoveycode', 1)} className="text-decoration-underline text-dark font-weight-600 forgot-text text-14">Use Recovery Code ?</a></h6>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div> :
                                                                                    <></>
                                                                            }
                                                                            {
                                                                                !props.is2FA ?
                                                                                    <>

                                                                                        <div className="col-12 col-sm">
                                                                                            <div className="form-group validate-input">
                                                                                                <label className="checkbox checkbox-outline-primary mb-0">
                                                                                                    <input type="checkbox" onChange={props.handleSignIn} id="form-rememberme" name="rememberme" checked={props.loginData.rememberme} />
                                                                                                    <span className="text-gray-600">Remember me</span>
                                                                                                    <span className="checkmark"></span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-auto">
                                                                                            <h6 className="text-right" onClick={(e) => { e.preventDefault(); JsCall.handleError('form-username', false); props.setState({ ...props.state, forgetData: { ...props.state.forgetData, forgetpassword: !props.state.forgetData.forgetpassword } }) }}><a href="" className="text-decoration-underline text-primary">Forgot password?</a></h6>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </>
                                                                // log in form
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center mb-4">
                                                    {
                                                        props.state.forgetData.forgetpassword ?
                                                            <>
                                                                <button onClick={(e) => { props.setState({ ...props.state, addButtonDisable: true }); props.forgetPassword(e) }} disabled={props.state.addButtonDisable} className="btn btn-primary-dark btn-lg btn-block" type="submit" id="forgetpassword" >
                                                                    {
                                                                        props.state.addButtonDisable ?
                                                                            <Spinner content={`${Config.sendotpbutton}...`} />
                                                                            :
                                                                            Config.sendotpbutton
                                                                    }
                                                                </button>
                                                            </>
                                                            :
                                                            props.state.otpData.verifyotp ?
                                                                <>
                                                                    <button onClick={(e) => { props.setState({ ...props.state, addButtonDisable: true }); props.changePassword(e) }} disabled={props.state.addButtonDisable} className="btn btn-primary-dark btn-lg btn-block" type="submit" id="otp" >
                                                                        {
                                                                            props.state.addButtonDisable ?
                                                                                <Spinner content={`${Config.changepassword}...`} />
                                                                                :
                                                                                Config.changepassword
                                                                        }
                                                                    </button>
                                                                </>
                                                                :
                                                                <button onClick={(e) => { props.setState({ ...props.state, addButtonDisable: true }); props.signIn(e) }} disabled={props.state.addButtonDisable} className="btn btn-primary-dark btn-lg btn-block" type="submit" id="signin">
                                                                    {
                                                                        props.state.addButtonDisable ?
                                                                            <Spinner content={`${Config.signinbutton}...`} />
                                                                            :
                                                                            Config.signinbutton
                                                                    }
                                                                </button>
                                                    }
                                                </div>
                                            </form>

                                        </div>
                                    </div>


                                </div>



                                <div className="login-wrpper d-none">
                                    <div className="login-inner-wrapper">
                                        <div className="login-card">
                                            <div className="login-header">
                                                <figure>
                                                    <img src={big_logo} />
                                                </figure>
                                            </div>
                                            <form className="signup-input">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <div className="form-group validate-input">
                                                                    <label className="label-form-control label-form-control-lg">First Name<span className="text-danger"> * </span></label>
                                                                    <input className="form-control form-control-lg" placeholder={`Enter First Name`} id="form-firstname" name="firstname" onChange={(e) => props.handleSignUp('text', 'firstname', e.target.value)} type="text" />
                                                                    <label className="input-group-text-lg" htmlFor="username">
                                                                        <ActionIcons type='person' />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <div className="form-group validate-input">
                                                                    <label className="label-form-control label-form-control-lg">Last Name<span className="text-danger"> * </span></label>
                                                                    <input className="form-control form-control-lg" placeholder={`Enter Last Name`} id="form-lastname" name="lastname" onChange={(e) => props.handleSignUp('text', 'lastname', e.target.value)} type="text" />
                                                                    <label className="input-group-text-lg" htmlFor="username">
                                                                        <ActionIcons type='person' />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group validate-input">
                                                                    <label className="label-form-control label-form-control-lg">E-mail<span className="text-danger"> * </span></label>
                                                                    <input className="form-control form-control-lg" placeholder={`Enter E-mail`} id="form-email" name="email" onChange={(e) => props.handleSignUp('text', 'email', e.target.value)} type="type" />
                                                                    <label className="input-group-text-lg" htmlFor="username">
                                                                        <ActionIcons type="email" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group validate-input">
                                                                    <label className="label-form-control label-form-control-lg">Company Name<span className="text-danger"> * </span></label>
                                                                    <input className="form-control form-control-lg" placeholder={`Enter Company Name`} id="form-companyname" name="companyname" onChange={(e) => props.handleSignUp('text', 'companyname', e.target.value)} type="text" />
                                                                    <label className="input-group-text-lg" htmlFor="username">
                                                                        <ActionIcons type="business" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group validate-input">
                                                                    <label className="label-form-control label-form-control-lg">Subdomain Name<span className="text-danger"> * </span></label>
                                                                    <input className="form-control form-control-lg" placeholder={`Enter Subdomain Name`} id="form-subdomainname" name="subdomainname" onChange={(e) => props.handleSignUp('text', 'subdomainname', e.target.value)} type="text" />
                                                                    <span className="input-group-text">{Config.regdomain}</span>
                                                                    <label className="input-group-text-lg" htmlFor="username">
                                                                        <ActionIcons type="language" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    {/* <div className="col-md-6">
                                                                        <div className="row"> */}
                                                                    {/* <div className="col-auto"> */}
                                                                    {/* <div className="form-group validate-input"> */}
                                                                    {/* <SelectPicker cleanable={false} className="form-control has-val" data={[]} id="form-countryid" name="countryid" onSelect={(e)=>(e) => props.handleSignUp('text', 'lastname', e.target.value)(e)} /> */}
                                                                    {/* <select className="form-control-lg has-val" id="form-countryid" onChange={(e) => props.handleSignUp('text', 'lastname', e.target.value)} name="countryid" data-size="10" data-dropup-auto="false">
                                                                                        <option value="1">IN</option>
                                                                                        <option value="2">EG</option>
                                                                                        <option value="3">IQ</option>
                                                                                        <option value="4">MN</option>
                                                                                    </select> */}
                                                                    {/* </div> */}
                                                                    {/* <input className="form-control" id="contact" type="text" placeholder="Contact No." /> */}
                                                                    {/* <label className="label-form-control">Contact No.<span className="text-danger"> * </span></label> */}
                                                                    {/* </div> */}
                                                                    {/* <div className="col">
                                                                                <div className="form-group validate-input">
                                                                                    <input className="form-control form-control-lg countrylookup px-3" id="form-countryname" name="countryname" onChange={(e) => props.handleSignUp('text', 'countryname', e.target.value)} type="text" placeholder="Country" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="col-12">
                                                                        <div id="form-contact" className="form-group validate-input">
                                                                            <div>
                                                                                <label className="label-form-control label-form-control-lg">Contact No.<span className="text-danger"> * </span></label>
                                                                                <IntlTelInput
                                                                                    inputId="form-contact"
                                                                                    preferredCountries={['in']}
                                                                                    inputClassName="form-control form-control-lg"
                                                                                    placeholder={`Enter Contact No.`}
                                                                                    value={props.phoneno}
                                                                                    onSelectFlag={(num, country) => {
                                                                                        props.handleSignUp('intl-tel-input', 'countryid-codecontact', country)
                                                                                    }}
                                                                                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                                                        props.handleSignUp('intl-tel-input', 'contact', value)
                                                                                    }}
                                                                                // onPhoneNumberBlur={(status, value, countryData, number, id) => {
                                                                                // console.log('onPhoneNumberBlur value', value)
                                                                                // console.log('onPhoneNumberBlur number', number)
                                                                                // }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-auto">
                                                                        <div className="form-group validate-input">
                                                                            
                                                                            <select className="form-control-lg has-val" id="form-codecontact" onChange={(e) => props.handleSignUp('text', 'lastname', e.target.value)} name="codecontact" data-size="10" data-dropup-auto="false">
                                                                                <option value="1">+91</option>
                                                                                <option value="2">+36</option>
                                                                                <option value="3">+354</option>
                                                                                <option value="4">+254</option>
                                                                            </select>
                                                                        </div> */}
                                                                    {/* <input className="form-control" id="contact" type="text" placeholder="Contact No." /> */}
                                                                    {/* <label className="label-form-control">Contact No.<span className="text-danger"> * </span></label> */}
                                                                    {/*</div>
                                                                    <div className="col">
                                                                        <div className="form-group validate-input">
                                                                            <input className="form-control-lg px-3" id="form-contact" name="contact" onChange={(e) => props.handleSignUp('text', 'lastname', e.target.value)} type="number" placeholder="Contact No." />
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group validate-input">
                                                                    <label className="label-form-control label-form-control-lg">Password<span className="text-danger"> * </span></label>
                                                                    <input className="form-control form-control-lg" placeholder={`******`} id="form-password" name="password" onChange={(e) => props.handleSignUp('text', 'password', e.target.value)} type="password" />
                                                                    <label className="input-group-text-lg" htmlFor="password">
                                                                        <ActionIcons type='lock' />
                                                                    </label>
                                                                    <label className="input-group-text-lg input-group-right" htmlFor="form-password">
                                                                        <ActionIcons type="visibilityfill" onClick={() => handlePasswordField("password")} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group validate-input">
                                                                    <label className="label-form-control label-form-control-lg">Confirm Password<span className="text-danger"> * </span></label>
                                                                    <input className="form-control form-control-lg" placeholder={`******`} id="form-confirmpassword" name="confirmpassword" onChange={(e) => props.handleSignUp('text', 'confirmpassword', e.target.value)} type="password" />
                                                                    <label className="input-group-text-lg" htmlFor="confirmpassword">
                                                                        <ActionIcons type='lock' />
                                                                    </label>
                                                                    <label className="input-group-text-lg input-group-right" htmlFor="form-confirmpassword">
                                                                        <ActionIcons type="visibilityfill" onClick={() => handlePasswordField("confirmpassword")} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group validate-input">
                                                                    <label className="checkbox checkbox-outline-primary mb-0">
                                                                        <input type="checkbox" id="form-termsandcondition" name="termsandcondition" onChange={(e) => props.handleSignUp('checkbox', 'termsandcondition', e.target.checked)} />
                                                                        <span>I agree to the <a href="" onClick={(e) => { e.preventDefault() }}>Tearms of Service</a> and <a href="" onClick={(e) => { e.preventDefault() }}>Privacy Policy</a></span>
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center mb-3 mt-1">
                                                    <button onClick={props.signUp} className="btn btn-primary-dark btn-lg btn-block" type="submit" id="signup" disabled={!props.tnc}>{Config.signupbutton}</button>
                                                </div>
                                            </form>
                                            <div className="text-center">
                                                <h6>Already have an account? <a href="" onClick={handleForm} className="text-decoration-underline">Sign In</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* 2FA Recovery Modal  */}

            <DualAuthRecovery
                open={getCurrentState().modal.recoveycode}
                data={props.dualAuth}
                onClose={() => props.handleGrid(false, 'modal', 'recoveycode', 0)}
                verifyOTP={(otp) => {
                    props.setRecoveryButtonDisable(true)
                    props.handleVerifyOTP(otp)
                }}
                title={'Recovery Code'}
                recoveryButtonDisable={props.recoveryButtonDisable}
                setRecoveryButtonDisable={props.setRecoveryButtonDisable}
                handleGrid={props.handleGrid}
            />
        </>

    )
}

export default Index 